import CreditSummaryFooter from '@/app/components/CreditSummaryFooter'
import useGTagOnRender from '@/app/hooks/useGTagOnRender'
import useScrollToTopOnSecondRender from '@/app/hooks/useScrollToTopOnSecondRender'
import { Box, Card, Grid, Typography } from '@mui/material'
import pluralize from 'pluralize'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DatesData, DatesPageProps, UserFormProps } from '../FormTypes'
import { FormDatePickerRangeList } from '../formComponents/FormDatePicker'

const DateCaringForOther: React.FC<UserFormProps & DatesPageProps> = ({
  onContinue,
  onBack,
  updateDates,
  covidDates2020,
  covidDates2021,
  isPosting,
}) => {
  const {
    handleSubmit,
    reset: _reset,
    formState: { isValid, errors, isDirty },
    ...formMethods
  } = useForm<DatesData>({
    defaultValues: {
      covidDates2020: covidDates2020.filter((range) => range.reason === 'OTHER') ?? [],
      covidDates2021: covidDates2021.filter((range) => range.reason === 'OTHER') ?? [],
    },
    mode: 'onChange',
  })
  useScrollToTopOnSecondRender()
  useGTagOnRender('date-for-other')

  const daysUsed = useMemo(() => {
    let days = { '2020': 0, '2021': 0 }
    covidDates2020?.forEach((range) => (range.reason === 'PERSONAL' ? (days[2020] += range.days) : null))
    covidDates2021?.forEach((range) => (range.reason === 'PERSONAL' ? (days[2021] += range.days) : null))
    return days
  }, [covidDates2020.length, covidDates2021.length])

  // useEffect(() => {
  //   if (process.env.NEXT_PUBLIC_TESTMODE) {
  //     formMethods.setValue('covidDates2020', [
  //       { reason: 'CHILD', start: '10/10/2020', end: '10/20/2020', days: 11 },
  //       { reason: 'PERSONAL', start: '10/20/2020', end: '10/30/2020', days: 11 },
  //       { reason: 'OTHER', start: '11/1/2020', end: '11/11/2020', days: 11 },
  //     ])
  //     formMethods.setValue('covidDates2021', [
  //       { reason: 'CHILD', start: '05/10/2021', end: '05/20/2021', days: 11 },
  //       { reason: 'PERSONAL', start: '05/20/2021', end: '05/22/2021', days: 2 },
  //       { reason: 'OTHER', start: '06/1/2021', end: '06/3/2021', days: 2 },
  //     ])
  //   }
  // }, [])

  const { covidDates2020: otherDates2020, covidDates2021: otherDates2021 } = formMethods.watch()

  const [sumDays, setSumDays] = useState<number>()

  const getRemainingDaysString = (daysUsed: number) => {
    const remaining = 10 - daysUsed
    return `(${daysUsed} personal ${pluralize('day', daysUsed)} already entered, ${remaining > 0 ? `${remaining} ${pluralize('day', remaining)}` : ' no more days'} available for credit)`
  }

  useEffect(() => {
    let sum = 0
    otherDates2020?.map((range) => (range.reason === 'OTHER' ? (sum += parseInt(`${range.days}`)) : null))
    otherDates2021?.map((range) => (range.reason === 'OTHER' ? (sum += parseInt(`${range.days}`)) : null))
    let remoteSum = 0
    covidDates2020?.map((range) => (range.reason === 'OTHER' ? (remoteSum += parseInt(`${range.days}`)) : null))
    covidDates2021?.map((range) => (range.reason === 'OTHER' ? (remoteSum += parseInt(`${range.days}`)) : null))
    if (remoteSum !== sum) {
      updateDates({ dates2020: otherDates2020, dates2021: otherDates2021, reason: 'OTHER' })
    }
    setSumDays(sum)
  }, [otherDates2020?.length, otherDates2021?.length])

  return (
    <div
      className="grid gap-3 justify-stretch items-start grid-rows-5 direction-col flex-1 pt-8"
      style={{
        gridTemplateRows: 'auto auto 1fr auto auto',
        width: '100%',
      }}>
      <Card className="py-4 px-2">
        <Typography
          className="text-gradient text-center "
          variant="h6"
          sx={{
            fontSize: '1.4rem !important',
            mt: 0,
            mb: 4,
            lineHeight: 1,
            fontWeight: '700',
          }}>
          Caring For Others
        </Typography>
        <div className="flex flex-1 flex-col items-center">
          <div style={{ maxWidth: 720 }}>
            <div className="subtitle1 text-center">
              Please choose the dates when you couldn&apos;t work because you had to care for someone else who was sick
              or quarantined due to COVID.
            </div>
          </div>
        </div>
      </Card>
      <Grid container gridTemplateRows={'auto auto auto 1fr'} display={'grid'} gap={3}>
        <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center p-4 gap-3">
            <h5 className="text-start font-normal" style={{ maxWidth: 720, margin: 0 }}>
              Remember
            </h5>
            <ul>
              <li className="text-sm text-text-body" style={{ maxWidth: 720, lineHeight: 1.45 }}>
                The FFCRA offers self-employed individuals financial relief for up to{' '}
                <span className="font-bold">20</span> days of missed work due to COVID-related issues for personal care
                and caring for others.
              </li>
              <li className="text-sm text-text-body" style={{ maxWidth: 720, lineHeight: 1.45 }}>
                Days tracked for personal care are counted before days tracked here.
              </li>
              <li className="text-sm text-text-body" style={{ maxWidth: 720, lineHeight: 1.45 }}>
                Please note that you cannot use the same date twice across any of these categories.
              </li>
            </ul>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center p-4 gap-3">
            <Box
              sx={{
                gap: 2,
                display: 'flex',
                flexDirection: 'column',
                // flexWrap: 'wrap',

                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.4rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 500,
                }}>
                Select up to 10 days
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontSize: '.8rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 400,
                }}>
                (between 4/1/2020 and 3/31/2021)
              </Typography>
            </Box>
            {daysUsed[2020] > 0 ? (
              <span
                className={`subtitle1 text-center ${10 - daysUsed[2020] <= 0 ? 'text-gray-500' : 'text-green-700'}`}>
                {getRemainingDaysString(daysUsed[2020])}
              </span>
            ) : null}
            <div className="flex flex-row justify-center items-center">
              {10 - daysUsed[2020] > 0 ? (
                <FormDatePickerRangeList
                  name="covidDates2020"
                  label=""
                  helpText="Please track those days:"
                  minDate="2020-04-01"
                  maxDate="2021-03-31"
                  shouldReset={false}
                  maxDays={Math.max(10 - daysUsed[2020], 0)}
                  reason="OTHER"
                  pickedRanges={covidDates2020}
                  {...formMethods}
                />
              ) : null}
            </div>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center p-4 gap-3">
            <Box
              sx={{
                gap: 2,
                display: 'flex',
                flexDirection: 'column',
                // flexWrap: 'wrap',

                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.4rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 500,
                }}>
                Select up to 10 days
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontSize: '.8rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 500,
                }}>
                between 4/1/2021 and 9/30/2021
              </Typography>
            </Box>

            {daysUsed[2021] > 0 ? (
              <span
                className={`subtitle1 text-center ${10 - daysUsed[2021] <= 0 ? 'text-gray-500' : 'text-green-700'}`}>
                {getRemainingDaysString(daysUsed[2021])}
              </span>
            ) : null}
            <div className="flex flex-row justify-center items-center">
              {10 - daysUsed[2021] > 0 ? (
                <FormDatePickerRangeList
                  name="covidDates2021"
                  label=""
                  helpText="Please track those days:"
                  minDate="2021-04-01"
                  maxDate="2021-09-30"
                  shouldReset={false}
                  maxDays={Math.max(10 - daysUsed[2021], 0)}
                  reason="OTHER"
                  pickedRanges={covidDates2021}
                  {...formMethods}
                />
              ) : null}
            </div>
          </Card>
        </Grid>
      </Grid>

      <CreditSummaryFooter onBack={onBack} onNext={onContinue} isPosting={isPosting} />
    </div>
  )
}

export default DateCaringForOther
