'use client'
import StepperWrapper from '@core/styles/mui/stepper'
import { hexToRGBA } from '@core/utils/hex-to-rgba'
import { ChevronRight, LinkRounded, Money, Shield } from '@mui/icons-material'
import { AppBar, Link, Typography, styled } from '@mui/material'
import { default as MuiStep, StepProps } from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { CalendarIcon, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import clsx from 'clsx'
import { config } from 'dotenv'
import { useAtomValue, useSetAtom } from 'jotai'
import LogRocket from 'logrocket'
import { useRouter, useSearchParams } from 'next/navigation'
import React, { useEffect, useMemo, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import './utils/string.extensions'
import ThemeComponent from './@core/theme/ThemeComponent'
import { applicantInfoAtom, userIdAtom, userTokenAtom, userTypeAtom } from './atoms'
import useUserInfo from './hooks/useUserInfo'
import { FinancialInfo } from './steps'
import AccountInfo from './steps/AccountInfo'
import { EnterDates } from './steps/EnterDates'
import { Steps } from './steps/FormTypes'
import { Prequalifier } from './steps/Prequalifier'
import { TaxDashboard } from './steps/TaxDashboard'
import { devlog } from './utils'
import { useWindowSize } from './utils/useWindowSize'
import { ResumeSection } from './components/ResumeButton'

config()

const queryClient = new QueryClient()

const stepMeta = [
  {
    title: 'Financial Info',
    icon: <Money />,
    subtitle: 'Income Details',
  },
  {
    title: 'Enter Dates',
    icon: <CalendarIcon />,
    subtitle: 'Enter Information',
  },
  {
    title: 'Personal Info',
    icon: <Shield />,
    subtitle: 'Enter Information',
  },
]

const Step = styled(MuiStep)<StepProps>(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  '&:first-of-type': {
    paddingLeft: 0,
  },
  '&:last-of-type': {
    paddingRight: 0,
  },
  '& .MuiStepLabel-iconContainer': {
    display: 'none',
  },
  '& .step-subtitle': {
    color: `${theme.palette.text.disabled} !important`,
  },
  '&:not(.Mui-completed)': {
    '& .step-title': {
      color: theme.palette.text.secondary,
    },
    '& + svg': {
      color: theme.palette.text.disabled,
    },
  },
  '&.Mui-completed': {
    '& .step-title': {
      color: theme.palette.text.disabled,
    },
    '& + svg': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiStepLabel-label.Mui-active .step-title': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
    '& + svg': { display: 'none' },
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(5),
    },
  },
}))

const testMode = process.env.NEXT_PUBLIC_TESTMODE ?? false
const defaultStep =
  (!!process.env.NEXT_PUBLIC_DEFAULTSTEP && +process.env.NEXT_PUBLIC_DEFAULTSTEP) || Steps.Prequalifier
const defaultId = process.env.NEXT_PUBLIC_DEFAULTID

export default function HomeWrapper() {
  const [userInfo, setUserInfo, loaded, { reloginRequired }] = useUserInfo()
  const userType = useAtomValue(userTypeAtom)
  const router = useRouter()
  useEffect(() => {
    if (userType === 'affiliate') {
      router.replace('/welcome')
    }
  }, [userType])

  // useEffect(() => {
  //   devlog('Init LogRocket')
  //   if (process.env.NODE_ENV === 'production') {
  //     LogRocket.init('claim-my-credit/claimmycredit', {
  //       rootHostname: 'claimmy.credit',
  //     })
  //   }
  // }, [])

  if (reloginRequired) {
    return (
      <div className="flex-1 flex flex-col justify-center items-center h-dvh">
        <ResumeSection isEnabled />
      </div>
    )
  }

  if (!loaded) return null

  return <Home userInfo={userInfo} setUserInfo={setUserInfo} />
}

function Home({ userInfo, setUserInfo }: { userInfo: UserInfo; setUserInfo: (val: UserInfo) => void }) {
  const userId = useAtomValue(userIdAtom)
  const userToken = useAtomValue(userTokenAtom)
  const [errorState, setErrorState] = useState<{ step: Steps; error: string } | null>(null)
  const [referralCode, setReferralCode] = useState<string>()
  const setApplicantInfo = useSetAtom(applicantInfoAtom)
  const router = useRouter()

  const [currentStep, _setCurrentStep] = useState<Steps>(() => {
    devlog('INIT CURRENT STEP', { defaultStep, userInfoStep: userInfo?.step, userInfo, userId, userToken })
    if (defaultStep !== Steps.Prequalifier) {
      return defaultStep as Steps
    }
    if (userInfo?.step !== undefined && userInfo?.step !== null) {
      devlog('🚀  userInfo?.step:', userInfo?.step)
      if (userInfo.step >= 20) {
        return Steps.TaxInfo
      }
      return userInfo.step as Steps
    }
    if (userId && userToken && userInfo.phone) {
      return Steps.FinancialInfo
    }
    if (userId && !userInfo.phone) {
      return Steps.AccountInfo
    }
    return defaultStep as Steps
  })
  const [previousStep, setPreviousStep] = useState<Steps | null>(null)

  useEffect(() => {
    if (!!userInfo.id && !!userInfo.step && userInfo.id === userId && userInfo.step !== currentStep) {
      setCurrentStep(userInfo.step)
    }
  }, [userInfo.id])

  const setCurrentStep = (newValue: Steps) => {
    devlog(
      'step change',
      JSON.stringify(
        {
          change: {
            previousStep,
            nextStep: newValue,
          },
        },
        null,
        2
      )
    )
    setPreviousStep(currentStep)
    if (newValue >= 20) {
      _setCurrentStep(2)
    } else {
      _setCurrentStep(newValue)
      setUserInfo({ step: newValue })
    }
  }

  const stepBack = (infoUpdates: UserInfo = {}) => {
    if (Object.keys(infoUpdates).length > 0) {
      setUserInfo({ step: currentStep })
      // setWantsSave(infoUpdates)
    }
    setCurrentStep(currentStep - 1)
  }

  const searchParams = useSearchParams()

  const { width = 1000 } = useWindowSize()

  useEffect(() => {
    if (searchParams.has('ref')) {
      const code = searchParams.get('ref')
      if (!!code && code !== referralCode) {
        setReferralCode(code)
        localStorage.setItem('referral_code', code)
      }
    }
    if (searchParams.has('customer_id')) {
      const customer_id = searchParams.get('customer_id') ?? undefined
      const source_tracking_id = searchParams.get('source_tracking_id') ?? undefined
      const first_name = searchParams.get('first_name') ?? undefined
      const last_name = searchParams.get('last_name') ?? undefined
      const email = searchParams.get('email') ?? undefined
      const phone = searchParams.get('phone') ?? undefined
      setApplicantInfo({ customer_id, source_tracking_id, first_name, last_name, email, phone })
    }
    if (searchParams.has('ref') || searchParams.has('customer_id')) {
      router.replace('/')
    }
  }, [])

  const InnerElement = useMemo(() => {
    switch (currentStep) {
      case Steps.FinancialInfo:
        return (
          <div
            className="flex-1 flex flex-col items-stretch mt-2 pl-2"
            key={Steps.FinancialInfo}
            id={`slider-item-${Steps.FinancialInfo}`}>
            <h5 className="text-gradient text-left font-black text-2xl md:text-2xl">
              Step 1:
              <br />
              Let&apos;s see what you could claim
            </h5>

            <FinancialInfo
              onBack={stepBack}
              onContinue={() => {
                setCurrentStep(Steps.EnterDates)
              }}
              setErrorState={(error) => setErrorState(error ? { step: Steps.FinancialInfo, error } : null)}
            />
          </div>
        )
      case Steps.EnterDates:
        return (
          <div
            className="flex-1 flex flex-col"
            key={Steps.EnterDates}
            id={`slider-item-${Steps.EnterDates}`}
            style={{ minHeight: '80vh' }}>
            <EnterDates
              onBack={stepBack}
              onContinue={() => {
                setCurrentStep(Steps.TaxInfo)
              }}
            />
          </div>
        )

      case Steps.TaxInfo:
        return (
          <div
            className="flex-1 flex flex-col"
            key={Steps.TaxInfo}
            id={`slider-item-${Steps.TaxInfo}`}
            style={{ minHeight: '80vh' }}>
            <TaxDashboard onBack={stepBack} onContinue={() => devlog('not implemented yet')} />
          </div>
        )
      default:
        return <></>
    }
  }, [currentStep])

  const MyStepper = useMemo(() => {
    if (width > 750) {
      return (
        <StepperWrapper sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stepper
            orientation="horizontal"
            activeStep={currentStep}
            connector={<ChevronRight />}
            sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {stepMeta.map((step, index) => {
              return (
                <Step
                  onClick={() => setCurrentStep(index)}
                  style={{ minWidth: '115px', marginBottom: 0 }}
                  key={index}
                  className={clsx({ active: currentStep === index })}>
                  <StepLabel>
                    <div className="step-label flex-col flex ">
                      {step.icon}
                      <Typography className={`step-title`}>{step.title}</Typography>
                    </div>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </StepperWrapper>
      )
    }
    return (
      <StepperWrapper
        sx={{ mt: 0, mb: 0, pt: 4, flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Stepper activeStep={currentStep}>
          {stepMeta.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>
                  {currentStep === index && <Typography className="text-sm ">{step.title}</Typography>}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </StepperWrapper>
    )
  }, [width, currentStep])

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex-1 flex flex-col h-2.5 bg-primary-white">
        <ThemeComponent
          settings={{
            themeColor: 'primary',
            mode: 'light',
            skin: 'default',
            footer: 'static',
            layout: 'vertical',
            lastLayout: 'vertical',
            direction: 'ltr',
            navHidden: false,
            appBarBlur: true,
            navCollapsed: false,
            contentWidth: 'boxed',
            toastPosition: 'top-right',
            verticalNavToggleType: 'accordion',
            appBar: 'fixed',
          }}>
          {currentStep === Steps.Prequalifier ? (
            <div
              className="flex-1 flex flex-col"
              key={Steps.Prequalifier}
              id={`slider-item-${Steps.Prequalifier}`}
              style={{ minHeight: '100vh', alignSelf: 'stretch' }}>
              <Prequalifier onContinue={() => setCurrentStep(Steps.AccountInfo)} />
            </div>
          ) : currentStep === Steps.AccountInfo ? (
            <div className="flex-1 flex flex-col" key={Steps.AccountInfo} style={{ minHeight: '100vh' }}>
              <AccountInfo
                referralCode={referralCode}
                onContinue={() => {
                  devlog('change in onContinue Account Info')
                  setCurrentStep(Steps.FinancialInfo)
                }}
              />
            </div>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AppBar
                color="default"
                className="layout-navbar-and-nav-container "
                position="static"
                elevation={0}
                sx={{
                  height: width > 750 ? 80 : 70,
                  justifyContent: 'space-between',
                  alignItems: width > 750 ? 'center' : 'center',
                  color: 'text.primary',
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 13,
                  width: '100vw',
                  paddingRight: width > 750 ? 10 : 4,
                  paddingLeft: width > 750 ? 10 : 4,
                  paddingTop: 2,
                  paddingBottom: width > 750 ? 2 : 0,
                  transition: 'border-bottom 0.2s ease-in-out',
                  backgroundColor: (theme) => hexToRGBA(theme.palette.background.paper, 1),
                }}>
                <div className={`flex ${width > 750 ? 'ml-3' : 'ml-1'}`}>
                  <div style={{ width: 40 }}>
                    <img alt="" style={{ width: '100%' }} src="/claim_my_credit_logo_no_bg 2.png" />
                  </div>
                </div>
                <div className="flex flex-1">{MyStepper}</div>
                <div className={`flex mr-6`}></div>
              </AppBar>
              <div className="flex-1 flex flex-col md:px-8 items-stretch" style={{ paddingBottom: 64 }}>
                {InnerElement}
              </div>
            </LocalizationProvider>
          )}
        </ThemeComponent>
      </div>
    </QueryClientProvider>
  )
}
