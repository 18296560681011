import { Button, Paper, Typography } from '@mui/material'
import { UserFormProps } from './FormTypes'
import { creditCalculator } from '../utils'
import useScrollToTopOnSecondRender from '../hooks/useScrollToTopOnSecondRender'
import useUserInfo from '../hooks/useUserInfo'

export const CreditSummary: React.FC<UserFormProps> = ({ onBack, onContinue }) => {
  const [userInfo] = useUserInfo()
  const creditData = userInfo

  const { estimate2020, estimate2021 } = creditCalculator(creditData)
  useScrollToTopOnSecondRender()
  return (
    <div
      className="grid gap-5 justify-start items-stretch grid-rows-5 direction-col flex-1"
      style={{
        gridTemplateRows: '1fr auto',
      }}>
      <Typography variant="h4">Your Credit Estimates:</Typography>

      <Typography variant="body1" fontWeight={500}>
        Your 2020 Credit: <strong>${estimate2020}</strong>
      </Typography>
      <Typography variant="body1" fontWeight={500}>
        Your 2021 Credit: <strong>${estimate2021}</strong>
      </Typography>

      <div className="flex flex-row justify-between">
        <Button type="button" variant="outlined" onClick={() => onBack?.()}>
          Back
        </Button>
        <div className="flex flex-row end gap-2">
          <Button type="submit" variant="contained" onClick={() => onContinue()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}
