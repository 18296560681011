'use client'
import { boxTokenAtom, boxUserFolderIdAtom } from '@/app/atoms'
import { useAtomValue } from 'jotai'
import { IntlProvider } from 'react-intl'
import dynamic from 'next/dynamic'

const ContentUploader = dynamic( () => import( 'box-ui-elements/es/elements/content-uploader/ContentUploader' ), {
  ssr: false,
} )

const en_us = require( 'box-ui-elements/i18n/en-US' )

export default function BoxFileUpload( props ) {
  const boxToken = useAtomValue( boxTokenAtom )
  const boxFolderId = useAtomValue( boxUserFolderIdAtom )

  // fileLimit, onCancel, onClose, onError, 

  return ( <IntlProvider locale="en" messages={ en_us }>
    <ContentUploader token={ boxToken } language="en" messages={ en_us } rootFolderId={ boxFolderId } onComplete={ ( e ) => props?.onSuccess( e ) }
      onClose={ ( e ) => props?.onClose( e ) } onError={ ( e ) => props?.onError( e ) }
    />
  </IntlProvider> )
}

// resp[0].id


const boxSampleResponse = [
  {
    "type": "file",
    "id": "1431212593346",
    "file_version": {
      "type": "file_version",
      "id": "1569324975746",
      "sha1": "ccb9c2bc0448e2c62f4c8eca701d8fd61fc19eeb"
    },
    "sequence_id": "0",
    "etag": "0",
    "sha1": "ccb9c2bc0448e2c62f4c8eca701d8fd61fc19eeb",
    "name": "YelpCategories.md",
    "description": "",
    "size": 3445,
    "path_collection": {
      "total_count": 1,
      "entries": [
        {
          "type": "folder",
          "id": "246873171931",
          "sequence_id": "0",
          "etag": "0",
          "name": "6f121c41-7f7a-47d9-95c5-9819821ac8df"
        }
      ]
    },
    "created_at": "2024-01-31T09:10:16-08:00",
    "modified_at": "2024-01-31T09:10:16-08:00",
    "trashed_at": null,
    "purged_at": null,
    "content_created_at": "2023-08-25T08:30:13-07:00",
    "content_modified_at": "2023-08-25T08:30:13-07:00",
    "created_by": {
      "type": "user",
      "id": "3978613466",
      "name": "box-playground",
      "login": "AutomationUser_651889_OrR9McnCj0@boxdevedition.com"
    },
    "modified_by": {
      "type": "user",
      "id": "3978613466",
      "name": "box-playground",
      "login": "AutomationUser_651889_OrR9McnCj0@boxdevedition.com"
    },
    "owned_by": {
      "type": "user",
      "id": "3978613466",
      "name": "box-playground",
      "login": "AutomationUser_651889_OrR9McnCj0@boxdevedition.com"
    },
    "shared_link": null,
    "parent": {
      "type": "folder",
      "id": "246873171931",
      "sequence_id": "0",
      "etag": "0",
      "name": "6f121c41-7f7a-47d9-95c5-9819821ac8df"
    },
    "item_status": "active"
  }
]