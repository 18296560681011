import BigNumber from 'bignumber.js'

declare global {
  interface String {
    asBigNumber: () => number
    asCurrency: () => string
    asCurrencyShort: () => string
    asCurrencyShortCeil: () => string
    truncate: (maxLength?: number) => string
    parseFloat: () => number | null
  }
}

String.prototype.asCurrency = function () {
  if (this) {
    const str = this.toString()
    if (typeof this === 'string') {
      return convertAmountToNativeDisplay(+str.replace(/[^0-9.]/, ''), 'USD', 0, true)
    }
  }
  return this?.toString?.()
}

String.prototype.asCurrencyShort = function () {
  if (this) {
    const num = +this
    if (num < 1000) {
      return '$' + num.toString()
    }

    if (num < 10000) {
      return '$' + (num / 1000).toFixed(1) + 'K'
    }

    return '$' + (num / 1000).toFixed(0) + 'K'
  }
  return this
}
String.prototype.asCurrencyShortCeil = function () {
  if (this) {
    const num = +this
    if (num < 1000) {
      return '$' + num.toString()
    }

    if (num < 10000) {
      return '$' + Math.ceil((num / 1000)) + 'K'
    }

    return '$' + Math.ceil((num / 1000)) + 'K'
  }
  return this
}

const usd = {
  alignment: 'left',
  assetLimit: 1,
  currency: 'USD',
  decimals: 2,
  emojiName: 'united_states',
  label: 'United States Dollar',
  mask: '[099999999999]{.}[00]',
  placeholder: '0.00',
  smallThreshold: 1,
  symbol: '$',
}

type BigNumberish = number | string | BigNumber

const convertAmountToNativeDisplay = (
  value: BigNumberish,
  nativeCurrency = 'USD',
  buffer?: number,
  skipDecimals?: boolean
) => {
  const { decimals } = usd
  if (value !== 0) {
    const display = handleSignificantDecimals(value, decimals, buffer, skipDecimals)
    return `${usd.symbol}${display}`
  }
  return `${usd.symbol}${value}`
}

const handleSignificantDecimals = (value: BigNumberish, decimals: number, buffer = 3, skipDecimals = false): string => {
  if (lessThan(new BigNumber(value).abs(), 1)) {
    decimals = new BigNumber(value).toFixed().slice(2).search(/[^0]/g) + buffer
    decimals = Math.min(decimals, 8)
  } else {
    decimals = Math.min(decimals, buffer)
  }
  const result = new BigNumber(new BigNumber(value).toFixed(decimals)).toFixed()
  const resultBN = new BigNumber(result)
  const resultdp = resultBN.dp()
  return (resultdp ? resultdp : 0) <= 2 ? resultBN.toFormat(skipDecimals ? 0 : 2) : resultBN.toFormat()
}

const lessThan = (numberOne: BigNumberish, numberTwo: BigNumberish): boolean => new BigNumber(numberOne).lt(numberTwo)
