'use client'
import { userIdAtom, userInfoReducerAtom, userTokenAtom } from '@/app/atoms'
import useGTagOnRender, { trackShare, trackUniqueEvent } from '@/app/hooks/useGTagOnRender'
import useGetBoxToken from '@/app/hooks/useGetBoxToken'
import useScrollToTopOnSecondRender from '@/app/hooks/useScrollToTopOnSecondRender'
import useUserInfo from '@/app/hooks/useUserInfo'
import { devlog } from '@/app/utils'
import { getLockrocketSessionUrl } from '@/app/utils/logrocket.utils'
import {
  AttachMoney,
  CheckCircle,
  EditNoteRounded,
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
  UploadFileRounded,
  WarningOutlined,
} from '@mui/icons-material'
import { Button, Card } from '@mui/material'
import { useAtomValue } from 'jotai'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { Steps, UserFormProps } from '../FormTypes'
import { TaxInfo } from '../TaxInfo'
import { FormInputCheckbox } from '../formComponents/FormCheckbox'
import { FormTextInput } from '../formComponents/FormTextInput'
import BoxFileUpload from './BoxFileUpload'
import { ReferralSection } from './ReferralSection'
import CreditSummaryFooter from '@/app/components/CreditSummaryFooter'

enum TaxDashboardSteps {
  taxInfo = -1,
  completeApplication = 0,
  submitToCPA = 1,
  ammendmentComplete = 2,
  irsProcessing = 3,
}

const workerUrl = process.env.NEXT_PUBLIC_WORKER
const parseUrl = process.env.NEXT_PUBLIC_PARSE_URL

interface QueryUserTaskResponseSuccess {
  success: true
  tasks: DBApplicantTask[]
  error: null
}
interface QueryUserTaskResponseFailure {
  success: false
  error: string
  tasks: null
}
export type QueryUserTaskResponse = QueryUserTaskResponseSuccess | QueryUserTaskResponseFailure

function hasCompleteAddress(userInfo: UserInfo) {
  return userInfo.street && userInfo.city && userInfo.state && userInfo.zip
}

function hasCompletedTaxInfo(userInfo: UserInfo) {
  return hasCompleteAddress(userInfo) && userInfo.dateOfBirth && userInfo.industry
}

function getTaxStepFromUserStep(step: number) {
  if (step < 20) {
    return TaxDashboardSteps.completeApplication
  }
  if (step < 30) {
    return TaxDashboardSteps.submitToCPA
  }
  if (step < 40) {
    return TaxDashboardSteps.ammendmentComplete
  }
  if (step < 50) {
    return TaxDashboardSteps.irsProcessing
  }
  return TaxDashboardSteps.completeApplication
}

export const TaxDashboard: React.FC<UserFormProps> = ({ onBack }) => {
  const [userInfo, _, isFetchingUserInfo, { requery }] = useUserInfo()

  const [dashboardStep, setDashboardStep] = useState<TaxDashboardSteps>(() =>
    hasCompletedTaxInfo(userInfo) ? getTaxStepFromUserStep(userInfo.step ?? 2) : TaxDashboardSteps.taxInfo
  )

  useEffect(() => {
    if (!!userInfo.id && hasCompletedTaxInfo(userInfo) && dashboardStep === 2) {
      setDashboardStep(TaxDashboardSteps.taxInfo)
    }
  }, [userInfo.id])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [dashboardStep])

  useScrollToTopOnSecondRender()
  useGTagOnRender('tax-dashboard')
  useGetBoxToken()
  const userId = useAtomValue(userIdAtom)
  const userToken = useAtomValue(userTokenAtom)

  const getUserTasks = async () => {
    let sessionUrl = getLockrocketSessionUrl()

    let queryUrl = `${workerUrl}/task`
    try {
      const response = await fetch(queryUrl, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      })
      const { tasks, message }: ApplicantTaskResponseData = await response?.json?.()
      if (response.ok && response.status === 200) {
        return tasks ?? []
      }
      devlog('useQueryUser >> getUserTasks NOT response.ok', JSON.stringify({ response, userToken }, null, 2))
      throw new Error(message)
    } catch (e) {
      throw new Error(JSON.stringify(e, null, 2))
    }
  }

  const {
    data: _taskData,
    error,
    refetch,
    dataUpdatedAt,
  } = useQuery<DBApplicantTask[]>('user-tasks', getUserTasks, {
    enabled: !!userToken,
  })

  useEffect(() => {
    if (!!userInfo.id && hasCompletedTaxInfo(userInfo) && dashboardStep === 2) {
      setDashboardStep(TaxDashboardSteps.taxInfo)
    }
  }, [userInfo.id])

  function returnToDates() {
    onBack?.()
    onBack?.()
  }

  const hasCompleted = !!_taskData?.filter((t) => t.is_completed).length
  const unfinishedTasks = _taskData?.filter((t) => !t.is_completed) ?? []
  const allTasksDone = unfinishedTasks.length === 0
  const hasCovidDates = (userInfo.covidDates2020?.length ?? 0) > 0 || (userInfo.covidDates2021?.length ?? 0) > 0

  const taskData = _taskData?.sort((a, b) => (a.category < b.category ? 1 : -1))

  devlog('dashboardStep', dashboardStep)

  useEffect(() => {
    if (allTasksDone && userInfo.id) {
      trackUniqueEvent('application_submitted', 2, userInfo.step ?? 2, userInfo.id)
    }
  }, [dataUpdatedAt, allTasksDone])

  if (dashboardStep === TaxDashboardSteps.taxInfo) {
    return <TaxInfo onContinue={() => setDashboardStep(TaxDashboardSteps.completeApplication)} onBack={onBack} />
  }

  return (
    <div
      className="flex gap-1 justify-start items-center flex-col flex-1 pt-6 pb-12"
      style={{
        maxWidth: '100vw',
        // gridTemplateRows: '1fr auto',
      }}>
      <div className="flex flex-1 flex-col items-stretch justify-start">
        <h5 className="text-gradient m-0 text-center">Tax Dashboard:</h5>
        <div className="body-1 text-text-body text-center" style={{}}>
          Your secure portal for everything you need to stay up to date on your return.
        </div>
        <div className="h-4" />
        <div className="bg-purple-700 p-1 flex flex-row justify-between items-center text-white subtitle-2 text-xs md:text-sm px-2 pr-4 sm:px-8">
          <div
            className={`text-center ${
              dashboardStep === TaxDashboardSteps.completeApplication
                ? 'bg-white rounded-r-full p-2 pl-4 pr-8 text-black font-semibold'
                : 'font-medium rounded-full text-white flex justify-center items-center'
            } `}>
            {dashboardStep === TaxDashboardSteps.completeApplication ? (
              <span>Complete Application</span>
            ) : (
              <>
                <span className="sm:hidden">Application</span>
                <span className="hidden sm:inline-block">Complete Application</span>
              </>
            )}
          </div>
          <div
            className={`text-center ${
              dashboardStep === TaxDashboardSteps.submitToCPA
                ? 'bg-white rounded-r-full p-2 pl-4 pr-8 text-black font-semibold'
                : 'font-medium rounded-full text-white flex justify-center items-center'
            } `}>
            {dashboardStep === TaxDashboardSteps.submitToCPA ? (
              <span>2. CPA Review</span>
            ) : (
              <>
                {/* <span className="sm:hidden">2</span> */}
                <span className="sm:hidden">CPA</span>
                <span className="hidden sm:inline-block">CPA Review</span>
              </>
            )}
          </div>
          <div
            className={`text-center ${
              dashboardStep === TaxDashboardSteps.ammendmentComplete
                ? 'bg-white rounded-r-full p-2 pl-4 pr-8 text-black font-semibold'
                : 'font-medium rounded-full text-white flex justify-center items-center'
            } `}>
            {dashboardStep === TaxDashboardSteps.ammendmentComplete ? (
              <span>3. E-File Prepared</span>
            ) : (
              <>
                {/* <span className="sm:hidden">3</span> */}
                <span className="sm:hidden">Filing</span>
                <span className="hidden sm:inline-block">E-File Prepared</span>
              </>
            )}
          </div>
          <div
            className={`text-center ${
              dashboardStep === TaxDashboardSteps.irsProcessing
                ? 'bg-white rounded-r-full p-2 pl-4 pr-8 text-black font-semibold'
                : 'font-medium rounded-full text-white flex justify-center items-center'
            } `}>
            {dashboardStep === TaxDashboardSteps.irsProcessing ? (
              <span>4. IRS Processing</span>
            ) : (
              <>
                {/* <span className="sm:hidden">4</span> */}
                <span className="sm:hidden">IRS</span>
                <span className="hidden sm:inline-block">IRS Processing</span>
              </>
            )}
          </div>
        </div>
        {userInfo.informationNeeded ? (
          <div>
            <h3 className="text-center">From your CPA</h3>
            <Card className="align-self-stretch flex p-4 flex-col">
              <div>{userInfo.informationNeeded}</div>
            </Card>
          </div>
        ) : null}
        {!hasCovidDates ? (
          <Card className="p-4 mt-8">
            <div className="p-2 flex flex-row gap-4 items-center rounded-xl justify-center">
              <WarningOutlined fontSize={'large'} />
              <div className="text-center my-2 font-semibold">Please return to the dates section</div>
            </div>
            <div className="text-center my-1">The SETC credit is for the self-employed that were impacted by COVID</div>
            <div className="text-center my-1">
              Then follow the instructions and enter the days you were impacted by COVID
            </div>
            <div className="text-center my-1">
              Your CPA will use these dates, along with your tax returns, to determine your eligibilty and credit amount
            </div>
            <div className="flex flex-col items-center pb-10 pt-6">
              <Button type="submit" variant="contained" onClick={() => returnToDates()}>
                Return To Dates Section
              </Button>
            </div>
            <SupportBlock />
          </Card>
        ) : null}
        {taskData?.filter((t) => !t.is_completed)?.length === 0 ? (
          <>
            <h3 className="text-center">Status</h3>
            <div className="text-center">
              <StatusSection step={userInfo.step ?? 22} />
            </div>
          </>
        ) : (
          <>
            <h3 className="text-center">To Do</h3>
            <TaskSection
              id={'todo_tasks'}
              tasks={taskData}
              refreshData={refetch}
              isCompleted={false}
              userInfo={userInfo}
            />
          </>
        )}
        <div className={`flex ${(userInfo?.step || 0) < 20 ? 'flex-col' : 'flex-col-reverse'}`}>
          {hasCompleted ? (
            <div>
              <h3 className="text-center">Completed</h3>
              <TaskSection
                id={'complete_tasks'}
                tasks={taskData}
                refreshData={refetch}
                isCompleted={true}
                userInfo={userInfo}
              />
            </div>
          ) : null}
          <ReferralSection trackShare={trackShare} />
        </div>
        <CreditSummaryFooter
          onBack={() => setDashboardStep(TaxDashboardSteps.taxInfo)}
          isPosting={false}
          hideNext
        />
      </div>
    </div>
  )
}

function SupportBlock() {
  return (
    <>
      <div className="text-center mb-2">If you have any questions please reach out:</div>
      <a href="tel:8014480609">
        <div className="text-base text-slate-600 text-center">Phone: (801) 448-0609</div>
      </a>
      <a href="sms:8014480609">
        <div className="text-base text-slate-600 text-center">SMS: (801) 448-0609</div>
      </a>
      <a href="mailto:support@claimmy.credit">
        <div className="text-base text-slate-600 text-center">Email: support@claimmy.credit</div>
      </a>
    </>
  )
}

function TaskSection({
  id,
  tasks,
  refreshData,
  isCompleted,
  userInfo,
}: {
  id?: string
  tasks?: DBApplicantTask[]
  refreshData?: () => void
  isCompleted: boolean
  userInfo: UserInfo
}) {
  const onlyHas1TaskLeft = (tasks ?? []).filter((t) => !t.is_completed).length === 1
  const hasCovidDates = (userInfo.covidDates2020?.length ?? 0) > 0 || (userInfo.covidDates2021?.length ?? 0) > 0
  const isAgreementEnabled = onlyHas1TaskLeft && hasCovidDates

  return (
    <div className="gap-4 flex flex-col" id={id}>
      {tasks
        ?.filter((t) => t.is_completed === isCompleted)
        ?.map((task) => {
          if (task.category === 'agreement') {
            return (
              <div id={`task_${task.category}`} key={task.id}>
                <AgreementTask task={task} refreshData={refreshData} isAgreementEnabled={isAgreementEnabled} />
              </div>
            )
          }
          if (task.category === 'payment_options') {
            return (
              <div id={`task_${task.category}`} key={task.id}>
                <PaymentTask userInfo={userInfo} task={task} refreshData={refreshData} />
              </div>
            )
          }
          return (
            <div id={`task_${task.category}`} key={task.id}>
              <DocumentUpload task={task} refreshData={refreshData} />
            </div>
          )
        })}
    </div>
  )
}

function PaymentTask(props: { userInfo: UserInfo; task: DBApplicantTask; refreshData?: () => void }) {
  const [userInfo, setUserInfo, loaded, { requery }] = useUserInfo()
  const [showPayment, setShowPayment] = useState(false)
  const userToken = useAtomValue(userTokenAtom)
  const [showFeeExplanation, setShowFeeExplanation] = useState(false)

  const mutationState = useMutation<{ success: boolean; error: string }, void, { message?: 'ok' }>(
    'mark-task-complete',
    async () => {
      let sessionUrl = getLockrocketSessionUrl()

      const response = await fetch(encodeURI(`${parseUrl}/api/task/${props.task.id}`), {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'x-logrocket-url': sessionUrl,
        },
      })
      const taskCompleteResp = await response.json()
      devlog('taskCompleteResp', taskCompleteResp)
      return { success: true, error: taskCompleteResp.error }
    },
    {
      onSuccess: (data) => {
        props.refreshData?.()
      },
    }
  )

  const onSubmit = async () => {
    mutationState.mutate({})
  }

  const {
    handleSubmit,
    reset,
    formState: { errors },
    ...formMethods
  } = useForm<{ digitalAgreement: boolean }>({
    defaultValues: {
      digitalAgreement: false,
    },
    mode: 'onChange',
  })

  return (
    <Card className="align-self-stretch flex p-4 flex-col">
      <div className="flex flex-1 flex-row justify-between">
        <div className="flex-row flex items-center">
          <div
            className={`${
              props.task.is_completed ? 'bg-green-900' : 'bg-purple-700'
            } w-12 h-12 rounded-full flex justify-center items-center`}>
            <AttachMoney className="text-white" />
          </div>
          <div className="w-4" />
          <div className="font-bold text-lg">{props.task.label}</div>
        </div>
        <div className="flex flex-1 justify-end items-center">
          {props.task.is_completed ? (
            <div className={`w-12 h-12 rounded-full flex justify-center items-center`}>
              <CheckCircle className="text-green-900 w-12 h-12" fontSize="large" />
            </div>
          ) : (
            <div
              className={`w-12 h-12 rounded-full flex justify-center items-center`}
              onClick={() => {
                setShowPayment(!showPayment)
              }}>
              {showPayment ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </div>
          )}
        </div>
      </div>
      {showPayment && (
        <div className="flex flex-col items-center mt-12">
          <div style={{ maxWidth: 750 }} className="flex flex-1 justify-start items-center flex-col">
            <div className="text-2xl font-semibold text-center text-gradient">Congratulations!</div>
            <div className="h-8" />
            <div className="rounded-xl p-4 flex flex-col items-stretch text-slate-700">
              <div className="text-base  font-medium text-center">
                Your CPA has qualified you to receive a credit in the amount of:
              </div>
              <div className="h-4" />
              <div className="flex flex-col items-stretch">
                {userInfo.credit2020 ? (
                  <>
                    <div className="flex flex-1 flex-row items-center gap-2">
                      <div className="flex-[4] text-base font-regular text-right">
                        {userInfo.credit2020?.toString()?.asCurrency()}
                      </div>
                      <div className="flex-[3] text-xs text-left ">2020 credit</div>
                    </div>
                    <div className="flex flex-1 flex-row items-center gap-2">
                      <div className="flex-[4] text-base font-regular text-right">
                        {' + '}
                        {userInfo.credit2021?.toString()?.asCurrency()}
                      </div>
                      <div className="flex-[3] text-xs text-left ">2021 credit</div>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-1 flex-row items-center gap-2">
                    <div className="flex-[4] text-base font-regular text-right">
                      {userInfo.estimatedCredit?.toString()?.asCurrency()}
                    </div>
                    <div className="flex-[3] text-xs text-left ">credit</div>
                  </div>
                )}
                <div className="flex flex-1 flex-row items-center gap-2">
                  <div className="flex-[4] text-base font-regular text-right">
                    {' - '}
                    {userInfo.noDiscountTotalFee?.toString()?.asCurrency()}
                  </div>
                  <div
                    className="flex-[3] text-xs text-left flex items-center "
                    onClick={() => setShowFeeExplanation(!showFeeExplanation)}>
                    filing fee {showFeeExplanation ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </div>
                </div>
                {showFeeExplanation ? (
                  <div className="p-2 flex flex-col items-center rounded-xl bg-slate-50 ">
                    <div className="text-sm text-slate-500 font-medium">
                      <div className="text-sm text-slate-700 font-medium text-center underline">Fee Explanation:</div>
                      <ul>
                        <li>SETC tax code expertise</li>
                        <li>Certified CPA preparing your tax forms</li>
                        <li>No upfront costs</li>
                        <li>Maximum refund</li>
                        <li>No math</li>
                        <li>Massive time savings</li>
                        <li>Competitive pricing</li>
                        <li>Audit protection</li>
                        <li>CPA assisted IRS appeals</li>
                      </ul>
                    </div>
                  </div>
                ) : null}
                <div className="flex flex-1 flex-row items-center gap-2">
                  <div className="flex-[4] text-base font-regular text-right">
                    <hr className="w-28" />
                  </div>
                </div>
                <div className="flex flex-1 flex-row items-center gap-2 relative">
                  <div className="bg-gradient-dark w-[90vw] max-w-[350px] absolute h-full " />
                  <div className="flex-[4] text-white text-2xl font-semibold text-right relative">
                    {((userInfo.estimatedCredit ?? 0) - (userInfo.noDiscountTotalFee ?? 0))?.toString()?.asCurrency()}
                  </div>
                  <div className="flex-[3] text-base text-left text-white relative"> cash refund</div>
                </div>
              </div>
            </div>
            {/* <div className="h-4" />
            <div className="p-4 flex flex-col items-center rounded-xl bg-slate-50 ">
              <div className="text-sm text-slate-700 font-medium text-center underline">What's next:</div>
              <div className="text-sm text-slate-500 font-medium">
                <ul>
                  <li>A CPA prepares all the forms</li>
                  <li>You sign the forms</li>
                  <li>You enter your Direct Deposit Account</li>
                  <li>Wait for your money!</li>
                </ul>
              </div>
            </div> */}
            {/* <div className="h-4" /> */}

            <div className="h-4" />
            <hr className="w-72" />
            <div className="h-2" />
            <div className="p-4 flex flex-col items-center rounded-xl bg-slate-50 w-full max-w-96">
              <div className="text-sm text-slate-600 text-center">2020 credits expire in less than a month.</div>
              <div className="text-base text-slate-800 font-medium text-center">
                Lock in your credits before they&apos;re gone
              </div>
            </div>
            <div className="h-2" />
            <div className="p-4 flex flex-col items-center rounded-xl bg-slate-50 w-full max-w-96">
              <div className="text-sm text-slate-600 text-center">
                Credit availability and speed is first come first serve.
              </div>
              <div className="text-base text-slate-800 font-medium text-center">Save your spot in line</div>
            </div>
            <div className="h-8" />
            <div className="text-lg text-slate-800 font-medium text-center">Amount owed now: $0</div>
            <div className="text-sm text-slate-500 font-medium text-center">No fee until you receive your credit</div>
            <div className="h-4" />

            <div className="text-black" style={{ maxWidth: 300 }}>
              <FormInputCheckbox
                rules={{ required: 'You must agree to proceed' }}
                name="digitalAgreement"
                label="I agree to have the filing fee deducted from my credit"
                {...formMethods}
              />
            </div>

            <div className="h-4" />
            <div>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={mutationState.isLoading}
                className="w-72 w-max-80">
                Continue
              </Button>
            </div>
            <div className="h-12" />
            <div className="bg-slate-50 w-full p-4">
              <div className="text-lg text-slate-800 text-center">Have questions?</div>
              <div className="h-2" />
              <a href="tel:8014480609">
                <div className="text-base text-slate-600 text-center">Phone: (801) 448-0609</div>
              </a>
              <a href="mailto:support@claimmy.credit">
                <div className="text-base text-slate-600 text-center">Email: support@claimmy.credit</div>
              </a>
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}

function AgreementTask(props: { task: DBApplicantTask; refreshData?: () => void; isAgreementEnabled: boolean }) {
  const [showAgreement, setShowAgreement] = useState(false)
  const userId = useAtomValue(userIdAtom)
  const userToken = useAtomValue(userTokenAtom)

  const mutationState = useMutation<
    { success: boolean; error: string },
    void,
    { signature: string; digitalAgreement: boolean }
  >(
    'save-file-id',
    async (data) => {
      let sessionUrl = getLockrocketSessionUrl()

      const response = await fetch(
        encodeURI(`${parseUrl}/api/box/${data.signature}/user/${userId}/category/${props.task.category}`),
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'x-logrocket-url': sessionUrl,
          },
        }
      )
      const resp = await response.json()
      devlog('resp', resp)
      return { success: true, error: resp.error }
    },
    {
      onSuccess: (data) => {
        props.refreshData?.()
      },
      onError: (err, variables) => {
        //TODO if err is 409 then tell the user somehow
      },
    }
  )

  const onSubmit = async (data: { signature: string; digitalAgreement: boolean }) => {
    mutationState.mutate(data)
  }

  const {
    handleSubmit,
    reset,
    formState: { errors },
    ...formMethods
  } = useForm<{ signature: string; digitalAgreement: boolean }>({
    defaultValues: {
      signature: '',
      digitalAgreement: false,
    },
    mode: 'onChange',
  })

  return (
    <Card
      className={`align-self-stretch flex p-4 flex-col`}
      style={{
        backgroundColor: !props.isAgreementEnabled ? '#dfdfdf' : 'white',
      }}>
      <div className="flex flex-1 flex-row justify-between">
        <div className="flex-row flex items-center">
          <div
            className={`${
              props.task.is_completed ? 'bg-green-900' : 'bg-purple-700'
            } w-12 h-12 rounded-full flex justify-center items-center`}>
            <EditNoteRounded className="text-white" />
          </div>
          <div className="w-4" />
          <div className="font-bold text-lg">{props.task.label}</div>
        </div>
        <div className="flex flex-1 justify-end items-center">
          {props.task.is_completed ? (
            <div style={{ color: '#1E1E1E' }}>Completed</div>
          ) : (
            <div
              className={`w-12 h-12 rounded-full flex justify-center items-center`}
              onClick={() => {
                if (!props.isAgreementEnabled) return
                setShowAgreement(!showAgreement)
              }}>
              {showAgreement ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </div>
          )}
        </div>
      </div>
      {showAgreement && (
        <div className="text-center pt-4">
          <div className="text-xl text-medium text-black">
            No one wants to get audited, or have to give credits or refunds back.
          </div>
          <p>
            To ensure your FFCRA Self Employed Tax Credit is filed correctly with the IRS we have partnered with
            Quantify Tax, powered by Vyde, to get certified CPAs to review your information, prepare your returns, and
            submit to the IRS on your behalf.
          </p>
          <hr className="my-8 border-gray-50" />
          <div className="text text-black font-semibold">
            Quantify has a service agreement they would like you to review and e-sign:
          </div>
          <div className="h-3" />
          <div style={{ maxHeight: 500 }} className="border border-solid border-gray-400 flex flex-col py-2">
            <div className="flex-1 overflow-y-scroll px-2">
              {agreementText.split('\n').map((line, i) => {
                const isHeader = !line.includes(' ') || line.toUpperCase() === line
                return (
                  <p
                    className={`${isHeader ? 'font-semibold text-center' : 'text-start'}`}
                    style={{ textIndent: isHeader ? 0 : 40 }}
                    key={i}>
                    {!isHeader ? '\t' : ''}
                    {line}
                  </p>
                )
              })}
            </div>
            <div className="border-0 border-t border-solid border-gray-200">
              <div className="h-3" />
              <div className="flex flex-row gap-4 justify-center items-center text-black">
                <div className="text-black" style={{ maxWidth: 350 }}>
                  <FormInputCheckbox
                    rules={{ required: 'You must accept digital signatures to sign the agreement and continue' }}
                    name="digitalAgreement"
                    label="I agree to use a digital signature for the purposes of this agreement"
                    {...formMethods}
                  />
                </div>
              </div>
              <div className="h-4" />
              <div className="flex flex-row gap-4 justify-center items-center text-black">
                <div>Digital Signature:</div>
                <FormTextInput
                  rules={{ required: true, minLength: { value: 2, message: 'Please enter your full name' } }}
                  name="signature"
                  label="Full Name"
                  autoComplete="family-name"
                  disabled={mutationState.isLoading}
                  {...formMethods}
                />
              </div>
            </div>
          </div>

          <div className="h-4" />
          <div>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={mutationState.isLoading}
              className="w-48">
              Sign Agreement
            </Button>
          </div>
        </div>
      )}
    </Card>
  )
}

function DocumentUpload(props: { task: DBApplicantTask; refreshData?: () => void }) {
  const [showUpload, setShowUpload] = useState(false)
  const [error, setError] = useState('')
  const userId = useAtomValue(userIdAtom)
  const userToken = useAtomValue(userTokenAtom)

  const mutationState = useMutation<{ documentId?: string; error: string }, void, { fileId: string }>(
    'save-file-id',
    async (data) => {
      let sessionUrl = getLockrocketSessionUrl()

      try {
        const response = await fetch(
          `${parseUrl}/api/box/${data.fileId}/user/${userId}/category/${props.task.category}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'x-logrocket-url': sessionUrl,
            },
          }
        )
        const resp = await response.json()
        devlog('resp', resp)
        return { documentId: resp.id, error: resp.error }
      } catch (err) {
        console.error('err', err)
        return { documentId: null, error: error }
      }
    },
    {
      onSuccess: (data) => {
        props.refreshData?.()
      },
    }
  )

  return (
    <Card className="align-self-stretch flex p-4 flex-col">
      <div
        className="flex flex-1 flex-row justify-between"
        onClick={() => {
          setShowUpload(!showUpload)
        }}>
        <div className="flex-row flex items-center">
          <div
            className={`${
              props.task.is_completed ? 'bg-green-900' : 'bg-purple-700'
            } w-12 h-12 rounded-full flex justify-center items-center`}>
            <UploadFileRounded className="text-white" />
          </div>
          <div className="w-4" />
          <div className="font-bold text-lg">{props.task.label}</div>
        </div>
        <div className="flex flex-1 justify-end items-center">
          {props.task.is_completed ? (
            <div style={{ color: '#1E1E1E' }}>Completed</div>
          ) : (
            <div className={`w-12 h-12 rounded-full flex justify-center items-center`}>
              {showUpload ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </div>
          )}
        </div>
      </div>
      {showUpload && (
        <div>
          <BoxFileUpload
            onSuccess={(e: { id?: string }[]) => {
              const fileId = e?.[0]?.id
              if (fileId) {
                mutationState.mutate({ fileId: fileId })
              }
            }}
            onClose={() => setShowUpload(false)}
            onError={(e: any) => setError(e)}
          />
          {error ? <div className="text-red-500">{error}</div> : null}
        </div>
      )}
    </Card>
  )
}

// function renderStatusSection(step: number) {
function StatusSection({ step }: { step: number }) {
  const userInfo = useAtomValue(userInfoReducerAtom)

  const { Status, Timeframe }: { Status: React.JSX.Element; Timeframe?: React.JSX.Element } = useMemo(() => {
    switch (step) {
      case Steps.TaxInfo:
      case Steps.SubmittedToCPA:
      case Steps.NewSubmission:
        return {
          Status: (
            <div>
              We&apos;ve notified the CPA that your application is ready for their review. Watch your email, we&apos;ll
              notify you as soon as they&apos;ve been able to do their initial review!
            </div>
          ),
          Timeframe: <div>Typically 1-2 Business Days</div>,
        }
      case Steps.WaitingForDocs:
      case Steps.DocumentsInReview:
        return {
          Status: (
            <div style={{ textAlign: 'left' }}>
              We&apos;ve notified the CPA that you&apos;ve uploaded the requested document(s). Watch your email,
              we&apos;ll notify you as soon as they&apos;ve been able to review!
            </div>
          ),
        }
      case Steps.Disqualified:
        return {
          Status: (
            <div style={{ textAlign: 'left' }}>
              <div>{userInfo.informationNeeded}</div>
              We&apos;re sorry, but the CPA has determined that the information you provided does not qualify for the
              FFCRA Self Employed Tax Credit.
              <div className="bg-slate-50 p-4 my-8">
                <div className="font-semibold">Details:</div>
                {userInfo.informationNeeded}
              </div>
              <div className="font-semibold">Notes:</div>
              <div className="h-2" />
              <div className="">
                As you did not need to complete filing, or receive any credits, you won&apos;t be charged for services
                rendered.
              </div>
              <div className="h-4" />
              <div>Thank you for using our services.</div>
            </div>
          ),
        }

      case Steps.Prequalified:
      case Steps.Paid:
      case Steps.FilingNotStarted:
      case Steps.FilingStarted:
      case Steps.FilingReadyForReview:
        return {
          Status: (
            <div style={{ textAlign: 'left' }}>
              <div className="h-4" />
              <div className="flex flex-col items-center p-2 rounded-lg gap-2">
                <div>
                  <div className="text-sm text-slate-500 italic text-center">Your CPA</div>
                  <img src="/vyde_logo.jpg" style={{ height: 100 }} className="bg-white shadow" />
                </div>
                <div className="text-gray-600 text-lg font-bold">{`${step === Steps.FilingReadyForReview ? 'Finalizing Returns' : step === Steps.FilingStarted ? 'Preparing Returns' : 'Starting Soon'}`}</div>
              </div>
              <div className="h-4" />
              <div className="text-base text-green-900 text-center">Watch out for emails from your CPA!</div>
              <div className="h-4" />
              <hr className="w-96" />
              <div className="h-6" />
              <div className="mb-4 text-lg text-center">Your 2020 and 2021 credits need to be filed separately</div>
              <div className="bg-slate-50 p-4">
                <div className="font-semibold">Your 2020 Ammended Returns:</div>
                <ul>
                  <li>
                    IRS requires <span className="underline">wet signatures.</span>
                  </li>
                  <li>IRS requires they are mailed in.</li>

                  <li>Your CPA will pepare these and email you a link where you can just download, print, and ship.</li>
                </ul>
              </div>
              <div className="h-4"></div>
              <div className="bg-slate-50 p-4">
                <div className="font-semibold">Your 2021 Ammended Returns:</div>
                <ul>
                  <li>
                    IRS allows <span className="underline">e-signatures.</span>
                  </li>
                  <li>IRS allows e-filing.</li>

                  <li>Your CPA will pepare these and email you a link where you can sign, then the CPA will e-file.</li>
                </ul>
              </div>
            </div>
          ),
          Timeframe: (
            <div>{step === Steps.FilingReadyForReview ? '1 Business Day' : 'Typically 5-7 Business Days'}</div>
          ),
        }

      // case Steps.FilingAnxiouslyWaiting:
      //   return <div>FilingAnxiouslyWaiting</div>
      //

      // case Steps.FilingWatingOn_8879:
      //   return <div>FilingWaitingOn_8879</div>
      //
      case Steps.FilingSigned:
      case Steps.Efiled:
        return {
          Status: (
            <div style={{ textAlign: 'left' }}>
              Your part is done, nice work!
              <ul style={{ margin: 0, paddingLeft: 20 }}>
                <li>
                  {step === Steps.FilingSigned
                    ? 'The CPA will e-file your return soon.'
                    : 'The CPA has e-filed your return!'}
                </li>
                <li> The IRS will review your file, which typically takes around 3 weeks, but might go longer. </li>
                <li>
                  After the review, any credits due are issued within 9 months, but typically this takes about 5 months.
                </li>
              </ul>
            </div>
          ),
          Timeframe: <div>About 3 Weeks for IRS Review</div>,
        }

      case Steps.IRSCompleted:
        return {
          Status: (
            <div style={{ textAlign: 'left' }}>
              Your part is done, and the IRS has accepted your amended return!
              <ul style={{ margin: 0, paddingLeft: 20 }}>
                <li> All that&apos;s left is waiting for your credits to process. </li>
                <li> Credits are typically issued within 5 months, but it can take up to 9 months. </li>
              </ul>
            </div>
          ),
          Timeframe: <div>5-9 Months</div>,
        }

      case Steps.IRSRejected:
        return {
          Status: (
            <div style={{ textAlign: 'left' }}>
              The IRS has denied your filing.
              <ul style={{ margin: 0 }}>
                <li> We&apos;re sorry this happened. </li>
                <li>
                  As you did not receive any credits, you will not be charged for services rendered, and any prepaid
                  fees will be refunded.
                </li>
                <li> Thank you for using our services. </li>
              </ul>
            </div>
          ),
        }

      case Steps.IRSCreditIssued:
        return {
          Status: (
            <div style={{ textAlign: 'left' }}>
              The IRS has issued your credits and is cutting you a check!
              <ul style={{ margin: 0 }}>
                <li> This will be a paper check, so keep an eye on your mailbox. </li>
                <li> Thank you for using our services! </li>
              </ul>
            </div>
          ),
          Timeframe: <div>3-4 Weeks</div>,
        }

      default:
        return {
          Status: (
            <div>
              Your application is going through the system. Please wait a couple minutes and reload the page to get
              updated status information. If this lasts for longer than an hour, please reach out to&nbsp;
              <a href={'mailto:support@claimmy.credit'}>support@claimmy.credit</a>.
            </div>
          ),
        }
    }
  }, [step])

  return (
    <Card>
      <div style={{ maxWidth: 960 }} className="flex justify-start p-4 flex-col items-center">
        <div className={'flex flex-col'} style={{ maxWidth: 640 }}>
          <div className="flex flex-col justify-start items-center">
            <div className="text-slate-600 font-semibold text-center mr-4" hidden={!Timeframe}>
              Current Status:
            </div>
            <div className="flex-1 text-center">{Status}</div>
          </div>
          <div className={`flex flex-col justify-start items-center ${!Timeframe ? 'hidden' : ''}`}>
            <div className="h-8" />
            <div className="text-slate-600 font-semibold text-center mr-4 mt-4 sm:mt-0">Timeframe:</div>
            <div className="flex-1 text-center">{Timeframe}</div>
          </div>
        </div>
      </div>
    </Card>
  )
}

const agreementText = `FFCRA SELF EMPLOYED TAX CREDIT SERVICES AGREEMENT

This FFCRA Self Employment Tax Credit Services Agreement (“Agreement”) is entered into by the undersigned client (“Client”) of Quantifi Tax, LLC (“Quantifi Tax”), a Utah limited liability company. Quantifi Tax and Client may each be referred to herein as a “Party” and together as the “Parties.” 

Recitals
WHEREAS, Client believes Client qualifies for the COVID-19 FFCRA Self Employment Tax Credit (the “Credit”) made available under the Coronavirus Aid, Relief, and Economic Security Act, and modified by the Taxpayer Certainty and Disaster Tax Relief Act of 2020, the American Rescue Plan Act of 2021, and the Infrastructure Investment and Jobs Act; 
WHEREAS, Client desires to hire Quantifi Tax and its processing partners to assist Client in calculating and claiming the Credit; and 
WHEREAS, Quantifi Tax will rely upon information provided by Client in providing the Services outlined herein and the client is responsible for the accuracy and completeness of the information provided.
Agreement
NOW THEREFORE, in consideration of the foregoing and the mutual promises and covenants contained herein, the receipt and sufficiency of which is hereby acknowledged, the Parties agree as follows: 
Engagement: Upon the terms and subject to the conditions hereof, Client hereby agrees to engage Quantifi Tax to provide Client with the Services as defined in Section 2 of this Agreement, and to compensate Quantifi Tax as outlined herein, and Quantifi Tax will provide Client with the Services as outlined herein. 
Services:
    a.    The scope of services to be provided by Quantifi Tax under this Agreement are as follows (“Services”): 
Quantifi Tax will assist Client in determining whether Client has eligibility for the credit.

Quantifi Tax’s processing partners will review the financial and tax documents received to ensure that they have all they need to process the credit on behalf of Client.

Quantifi Tax’s processing partners will use the necessary data to help calculate what the credit amount will be. Upon approval from Client, Quantifi Tax’s processing partners will file the necessary documents required by the Internal Revenue Service on behalf of Client.
Quantifi Tax shall rely upon all representations made by the Client, including, but not limited to, previous credits and other relief or benefits obtained by Client in assessing Client’s eligibility for the Credit. 

Client agrees that Quantifi Tax’s processing partners will monitor the status of the Credit.  Client will not be charged an additional fee for such services.  Client agrees to complete and execute Form 8821 – Tax Information Authorization.

The Services will not include the following: representation by Quantifi Tax before the IRS or other governmental entity; audits of Client’s representations or documentation provided, which is expected to be accurate and truthful; verification of Client’s information for accuracy or completeness, or to detect errors, fraud, or theft; identification or communication of deficiencies in Client’s internal controls; legal advice to Client. 
Client Obligations: Client agrees to 1) provide true and complete information to Quantifi Tax, including in the FFCRA Self Employment or similar document provided by Quantifi Tax to Client; 2) provide any supplemental information reasonably required by the IRS to process the Credit; 3) indemnify and hold harmless Quantifi Tax from any claim arising from Client’s production of false or misleading information, as further described below; and 4) properly report all credits. 

Financial:

The fee for Services outlined herein shall be an amount equal to twenty percent (20%) of the Gross Credit identified by Quantifi Tax, plus a $199 processing fee. The fee shall be due at the time the credit has been paid out by the IRS to the client. The client shall be required to enter bank information to receive their direct deposit during the filing process. Client agrees to authorize Quantifi Tax to debit the previously provided account for the amount due once the credit has been received.  In the event that a Credit is not identified and delivered to Client, there shall be no fee due for Quantifi Tax’s Services. Alternatively, this shall be the highest amount to be paid by Client.

Confidentiality: Client grants to Quantifi Tax the right to access Client’s confidential information (“Confidential Information”), including business and personal documents, records, processes, intellectual property, financial information, financial statements, and all other forms of proprietary client information, to provide the Services. Client agrees to provide all Confidential Information to Quantifi Tax necessary to provide the Services in a timely manner. Quantifi Tax will hold all Confidential Information in such a manner as to protect the Confidential Information from being accessed by third parties as required by law.  Quantifi Tax is under no obligation to store or otherwise retain Client’s Confidential Information or other Client files, and the Client is advised to retain its own paper and electronic version of all Client’s files, including without limitation, relevant source data, for at least six (6) years.  Quantifi Tax reserves the right to destroy, or otherwise dispose of, Client’s Confidential Information or other Client files at any time. 
Representations and Warranties:
Quantifi Tax represents and warrants that Quantifi Tax and its processing partners shall perform the Services in a professional and workmanlike manner. 

Client represents and acknowledges that Quantifi Tax’ Services to Client hereunder
are not exclusive and Quantifi Tax shall be free to furnish similar services to others, including other third parties who may be competitors of Client.  

Any potential Credit identified by Quantifi Tax is based on the information and
documentation provided by the Client. Quantifi Tax and its processing partners do not purport to provide any legal or professional tax advice outside of the calculated value of the Credit. 

Client represents and acknowledges that Quantifi Tax makes no guarantees concerning the Credit and that Quantifi Tax cannot control or determine whether the IRS will allow or disburse any Credit. 

EXCEPT FOR THE EXPRESS REPRESENTATIONS AND WARRANTIES
CONTAINED IN THIS SECTION, NEITHER QUANTIFI TAX NOR ANY OTHER PERSON ON QUANTIFI TAX’ BEHALF, HAS MADE OR MAKES ANY EXPRESS OR IMPLED REPRESENTAION OR WARRANTY, EITHER ORAL OR WRITTEN, WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, TRADE OR OTHERWISE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED AND CLIENT ACKNOWLEDGES THAT IT HAS NOT RELIED UPON ANY REPRESENTATION OR WARRANTY MADE BY QUANTIFI TAX OR ANY OTHER PERSON ON QUANTIFI TAX’ BEHALF, EXCEPT AS SPECIFICALLY PROVIDED IN THIS SECTION. 

Limitation of Remedies: Quantifi Tax’ liability under this Agreement is limited to actual direct damages and is limited to the amount Quantifi Tax received from Client. In no event shall Quantifi Tax be liable for any incidental, consequential, special, indirect, punitive, or third-party damages or claims including lost profits, lost savings, lost productivity, loss of data, and loss from interruption of business under this Agreement. 
Indemnification: Client will indemnify and hold harmless Quantifi Tax and its attorneys, directors, officers, agents, representatives, members, managers, employees, successors, and assigns, to the maximum extent permitted by law for any action, suit, or proceeding, whether civil, criminal, investigative, or administrative, arising from or related to this Agreement. Client shall indemnify, defend, and hold Quantifi Tax harmless from and against any and all costs, damages, claims, suits, actions, liabilities, losses and judgments, including attorney fees, arising out of or relating to Client’s (a) access to or use of the Services, (b) breach of any representation and warranty or any covenant under this Agreement, (c) providing false, misleading, or incorrect information (d) failure to properly claim tax credit on tax returns, and (e) negligence, fraud, or willful misconduct, including the negligence, fraud, or willful misconduct of the Client’s affiliates and representatives. 
Severability: If a court of competent jurisdiction holds that any provision of this Agreement is invalid, illegal, or unenforceable, that holding shall not affect the validity of the other provisions of this Agreement, which shall remain in full force and effect, and this Agreement shall be construed as if the offending provision(s) had not been contained herein. 
Modification: No part of this Agreement may be amended, modified, or waived in any way unless such amendment, modification, or waiver is set forth in a later writing signed by both Parties. A modification, amendment, or waiver of any one provision of this Agreement shall not be deemed to be a modification, amendment, or waiver of any other provision of this Agreement. 
Waiver. The Parties agree that any waiver of any part of this Agreement shall not be a waiver of any other part of the Agreement, nor shall any waiver of a breach of any part of this Agreement constitute a waiver of any breach of any other part of this Agreement or any other breach. 
Mediation and Jurisdiction. If a dispute arises from or related to this Agreement or the breach thereof, and if the dispute cannot be settled through direct discussions, then the Parties agree to engage in mediation through a mutually agreed-upon mediator. This Agreement will be governed and construed in all respects according to the laws of the state of Utah. Any action arising from or related to this Agreement shall be brought in the federal or state courts located in Salt Lake County or Utah County in the state of Utah. 
Attorney’s Fees: In any action to interpret or enforce the terms of this Agreement, the prevailing Party shall be entitled to recover its reasonable attorney fees and out-of-pocket costs incurred in connection with such action in addition to any other relief which may be awarded.
Entire Agreement. This Agreement constitutes the entire agreement and understanding between the Parties concerning the subject matter hereof and supersedes any prior Agreement or understanding relating to the subject matter of this Agreement, whether express or implied.
`
