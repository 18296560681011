import Masonry from '@mui/lab/Masonry'
import { Card, Typography, useTheme } from '@mui/material'
import { useAtomValue } from 'jotai'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import states from 'states-us'
import { applicantInfoAtom } from '../atoms'
import CreditSummaryFooter from '../components/CreditSummaryFooter'
import useQueryUser from '../hooks/useQueryUser'
import useUserInfo from '../hooks/useUserInfo'
import { devlog } from '../utils'
import { PersonalInfoData, Steps, UserFormProps } from './FormTypes'
import { FormDatePicker } from './formComponents/FormDatePicker'
import { FormSelect } from './formComponents/FormSelect'
import { FormTextInput } from './formComponents/FormTextInput'

export const TaxInfo: React.FC<UserFormProps> = ({ onContinue, onBack }) => {
  const [userInfo, setUserInfo] = useUserInfo()
  const [isPosting, setIsPosting] = useState(false)
  const applicantInfo = useAtomValue(applicantInfoAtom)
  const {
    handleSubmit,
    reset,
    formState: { errors, isValid },
    ...formMethods
  } = useForm<PersonalInfoData>({
    defaultValues: {
      dateOfBirth: userInfo?.dateOfBirth ?? '',
      nameOfCompany: userInfo?.nameOfCompany ?? '',
      industry: userInfo?.industry ?? '',
      street: userInfo?.street ?? '',
      street2: userInfo?.street2 ?? '',
      city: userInfo?.city ?? '',
      state: userInfo?.state ?? '',
      zip: userInfo?.zip ?? '',
    },
    mode: 'onChange',
  })

  const { postUser } = useQueryUser()

  const onSubmit: SubmitHandler<PersonalInfoData> = async (data) => {
    setIsPosting(true)
    const update = await postUser({ ...data, step: Steps.TaxInfo })
    if (update.success) {
      setUserInfo(update.userInfo)
      onContinue()
    } else {
      console.error('FinancialInfo >> onsubmit >> ', update.error, { update, data })
    }
    setIsPosting(false)
  }

  const state = formMethods.watch('state')
  useEffect(() => {
    devlog('state', state)
  }, [state])
  useEffect(() => {
    devlog('additional', JSON.stringify({ isValid, errors }, null, 2))
  }, [isValid, errors])

  // useEffect(() => {
  //   if (process.env.NEXT_PUBLIC_TESTMODE) {
  //     formMethods.setValue('nameOfCompany', 'Ronaldo Hills Solutions')
  //     formMethods.setValue('industry', 'Solutioning Stuff')
  //     formMethods.setValue('dateOfBirth', '05/05/1985')
  //     formMethods.setValue('street', '7069 Sabryna Circles')
  //     formMethods.setValue('street2', '')
  //     formMethods.setValue('city', 'O Reillyfort')
  //     formMethods.setValue('state', 'MI')
  //     formMethods.setValue('zip', '24805')
  //   }
  // }, [])

  const stateList = states.map((state) => state.abbreviation)
  const theme = useTheme()

  return (
    <div className="grid gap-3 justify-stretch items-start grid-rows-1 direction-col flex-1 pt-8">
      <div className="flex flex-1 gap-4 flex-col py-4" style={{}}>
        <Typography
          className="text-gradient text-center my-0"
          variant="h6"
          sx={{
            fontSize: '1.4rem !important',
            mt: 0,
            lineHeight: 1,
            fontWeight: '700',
          }}>
          Personal Info
        </Typography>
        <div className="subtitle1 text-center px-3">
          {/* <Typography variant="body1"> */}
          This info is solely used for purposes of filing your return. Your personal info will be safeguarded.
          {/* </Typography> */}
        </div>
        <hr />
        <Masonry columns={{ xs: 1, md: 2 }} spacing={3}>
          <Card className="p-4">
            <h6 className="m-1">Verification Info</h6>
            <div className="flex flex-col gap-4 pt-2">
              <FormDatePicker
                rules={{
                  required: { value: true, message: 'Date of Birth required' },
                  validate: (val) => !!val || 'Date of Birth required',
                }}
                autoComplete={'bday'}
                name="dateOfBirth"
                label="Your Date of Birth"
                {...formMethods}
              />
            </div>
          </Card>
          <Card className="p-4">
            <h6 className="m-1">Work Info</h6>
            <div className="flex flex-col gap-4 pt-2">
              <FormTextInput
                name="nameOfCompany"
                label="Company Name"
                helperText={'If applicable. Leave blank if doing business as yourself.'}
                {...formMethods}
              />
              {/* <div className="h-4" /> */}
              <FormTextInput
                required
                name="industry"
                label="Business Industry"
                {...formMethods}
                rules={{
                  required: 'Industry required',
                }}
              />
            </div>
          </Card>
          <Card className="p-4">
            <h6 className="m-1">Mailing Address</h6>
            <div className="flex flex-col gap-4 pt-2">
              <FormTextInput
                rules={{ required: 'Must enter street address' }}
                name="street"
                label="Street"
                autoComplete="street-address"
                {...formMethods}
              />
              <FormTextInput name="street2" label="Street (cont)" {...formMethods} />
              <FormTextInput
                rules={{ required: 'Must enter city name', minLength: { value: 3, message: 'Must enter city name' } }}
                name="city"
                label="City"
                autoComplete={'address-level2'}
                {...formMethods}
              />
              <FormSelect
                autoCapitalize="characters"
                autoComplete="address-level1"
                rules={{ required: 'Must select a state' }}
                name="state"
                label="State"
                options={stateList}
                {...formMethods}
              />
              <FormTextInput
                rules={{
                  required: 'Must enter zip code',
                  maxLength: { value: 5, message: 'Must enter zip code' },
                  minLength: { value: 5, message: 'Must enter zip code' },
                }}
                autoComplete="postal-code"
                name="zip"
                label="Zip"
                type="number"
                stripLeadingZeros={false}
                inputProps={{ maxLength: 5 }}
                {...formMethods}
              />
            </div>
          </Card>
        </Masonry>
      </div>
      <CreditSummaryFooter onBack={onBack} onNext={handleSubmit(onSubmit)} isPosting={isPosting} />
    </div>
  )
}
