import { Card, Grid, Typography, debounce } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useAtom } from 'jotai'
import React, { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { income2019Atom, income2020Atom, income2021Atom } from '../atoms'
import CreditSummaryFooter from '../components/CreditSummaryFooter'
import useGTagOnRender, { trackUniqueEvent } from '../hooks/useGTagOnRender'
import useQueryUser from '../hooks/useQueryUser'
import useScrollToTopOnSecondRender from '../hooks/useScrollToTopOnSecondRender'
import useUserInfo from '../hooks/useUserInfo'
import { devlog } from '../utils'
import { FinancialInfoData, Steps, UserFormProps } from './FormTypes'
import { FormSelect } from './formComponents/FormSelect'

const incomeOptions = [
  1, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 110000, 120000, 130000, 140000, 150000,
].reverse()

const incomeOptionLabels = [
  '1 - 10,000',
  '10,000 - 20,000',
  '20,000 - 30,000',
  '30,000 - 40,000',
  '40,000 - 50,000',
  '50,000 - 60,000',
  '60,000 - 70,000',
  '70,000 - 80,000',
  '80,000 - 90,000',
  '90,000 - 100,000',
  '100,000 - 110,000',
  '110,000 - 120,000',
  '120,000 - 130,000',
  '130,000 - 140,000',
  '140,000 - 150,000',
  '150,000+',
].reverse()

export const FinancialInfo: React.FC<UserFormProps> = ({ onContinue, onBack }) => {
  const [userInfo, setUserInfo] = useUserInfo()
  useScrollToTopOnSecondRender()
  useGTagOnRender('financial-info')
  const { postUser } = useQueryUser()

  const {
    handleSubmit,
    reset: _reset,
    formState: { isValid, errors, isDirty },
    ...formMethods
  } = useForm<FinancialInfoData>({
    defaultValues: {
      netIncome2019Range: userInfo?.netIncome2019 ?? null,
      netIncome2019: userInfo?.netIncome2019 ?? 0,
      netIncome2020: userInfo?.netIncome2020 ?? 0,
      netIncome2020Range: userInfo?.netIncome2020 ?? null,
      netIncome2021: userInfo?.netIncome2021 ?? 0,
      netIncome2021Range: userInfo?.netIncome2021 ?? null,
    },
    mode: 'onChange',
  })

  const [shouldReset, setShouldReset] = useState(false)

  const [isPosting, setIsPosting] = useState(false)
  const [income2019, setIncome2019] = useAtom(income2019Atom)
  const [income2020, setIncome2020] = useAtom(income2020Atom)
  const [income2021, setIncome2021] = useAtom(income2021Atom)

  const [se2019Higher, setSE2019Higher] = useState<string | null>(!!userInfo?.netIncome2019 ? 'YES' : 'NO')

  const { netIncome2019, netIncome2020, netIncome2021, netIncome2019Range, netIncome2020Range, netIncome2021Range } =
    formMethods.watch()

  useEffect(() => {
    if (!netIncome2020Range) {
      if (income2020) {
        setIncome2020(0)
        formMethods.setValue('netIncome2020', undefined)
      }
    } else if (+netIncome2020Range !== netIncome2020) {
      setIncome2020(+netIncome2020Range)
      formMethods.setValue('netIncome2020', +netIncome2020Range)
    }
    if (!netIncome2021Range) {
      if (income2021) {
        setIncome2021(0)
        formMethods.setValue('netIncome2021', undefined)
      }
    } else if (+netIncome2021Range !== netIncome2021) {
      setIncome2021(+netIncome2021Range)
      formMethods.setValue('netIncome2021', +netIncome2021Range)
    }

    if (!netIncome2019Range) {
      if (income2019) {
        setIncome2019(0)
        formMethods.setValue('netIncome2019', undefined)
      }
    } else if (+netIncome2019Range !== netIncome2019) {
      setIncome2019(+netIncome2019Range)
      formMethods.setValue('netIncome2019', +netIncome2019Range)
    }
  }, [netIncome2019Range, netIncome2020Range, netIncome2021Range])

  useEffect(() => {
    if (!!userInfo.netIncome2019 && userInfo.netIncome2019 !== netIncome2019) {
      formMethods.setValue('netIncome2019', userInfo.netIncome2019)
    }
    if (!!userInfo.netIncome2020 && userInfo.netIncome2020 !== netIncome2020) {
      formMethods.setValue('netIncome2020', userInfo.netIncome2020)
    }
    if (!!userInfo.netIncome2021 && userInfo.netIncome2021 !== netIncome2021) {
      formMethods.setValue('netIncome2021', userInfo.netIncome2021)
    }
    if (!!userInfo?.netIncome2019 && se2019Higher === 'NO') {
      setSE2019Higher('YES')
    }
  }, [userInfo.netIncome2019, userInfo.netIncome2020, userInfo.netIncome2021])

  const onSubmit: SubmitHandler<FinancialInfoData> = async ({
    netIncome2019: _netIncome2019 = 0,
    netIncome2020 = 0,
    netIncome2021 = 0,
    ...data
  }) => {
    const netIncome2019 =
      se2019Higher === 'YES' && _netIncome2019 && _netIncome2019 > (netIncome2020 ?? 0) ? _netIncome2019 : 0

    devlog('income log', 'isDirty', isDirty, se2019Higher)

    if (isDirty || (se2019Higher === 'NO' && !!userInfo.netIncome2019)) {
      setIsPosting(true)
      setIncome2019(netIncome2019)
      setIncome2020(netIncome2020)
      setIncome2021(netIncome2021)
      devlog('income log', { netIncome2019, netIncome2020, netIncome2021 })
      const updatedUser = { ...data, netIncome2019, netIncome2020, netIncome2021, step: Steps.EnterDates }
      const update = await postUser(updatedUser)
      setIsPosting(false)
      if (update.success && userInfo.id) {
        setUserInfo(updatedUser)
        trackUniqueEvent('financial_info_completed', 0, userInfo.step ?? 0, userInfo.id)
        onContinue?.()
      } else {
        console.error('FinancialInfo >> onsubmit >> ', update.error, { update, updatedUser })
      }
    } else {
      onContinue?.()
    }
  }

  const reset = () => {
    setShouldReset(true)
    _reset()
  }
  useEffect(() => {
    if (!isDirty && shouldReset) {
      setShouldReset(false)
    }
  }, [isDirty, shouldReset])

  const calculateEstimatedCredit = () => {
    setIncome2019(!!netIncome2019 ? netIncome2019 : 0)
    setIncome2020(!!netIncome2020 ? netIncome2020 : 0)
    setIncome2021(!!netIncome2021 ? netIncome2021 : 0)
  }

  const debounceCalculator = useCallback(debounce(calculateEstimatedCredit, 500), [
    netIncome2019,
    netIncome2020,
    netIncome2021,
  ])

  useEffect(() => {
    // devlog('ping!', { netIncome2019, netIncome2020, netIncome2021 })
    debounceCalculator()
    const incomeSum = (netIncome2019 ?? 0) + (netIncome2020 ?? 0) + (netIncome2021 ?? 0)
    if (incomeSum > 0) {
      formMethods.clearErrors()
    } else if (isDirty) {
      formMethods.setError('netIncome2019', { type: 'custom' })
      formMethods.setError('netIncome2020', { type: 'custom' })
      formMethods.setError('netIncome2021', { type: 'custom', message: 'At least one tax year must have income.' })
    }
  }, [netIncome2019, netIncome2020, netIncome2021])

  const validateIncome = (val: number) => {
    devlog('Validate income')
    return val + (netIncome2019 ?? 0) + (netIncome2020 ?? 0) + (netIncome2021 ?? 0) > 0
  }

  return (
    <div
      className="grid gap-2 justify-stretch items-start grid-rows-5 direction-col flex-1"
      style={{
        gridTemplateRows: 'auto auto 1fr auto auto',
        width: '100%',
      }}>
      <div>
        <div className="h-2" />
        <hr style={{ color: 'black' }} />
      </div>
      <Grid container gridTemplateRows={'auto auto auto 1fr'} display={'grid'} gap={6} className="">
        <Grid item xs={12}>
          <div className="text-xl font-bold text-left mb-2 ">Enter your approximate income for each year</div>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={2} className="p-4 grid gap-2 justify-start">
            <Typography variant="h6" className="mb-2">
              For year 2019
            </Typography>
            <div className="flex flex-row gap-4 flex-1 justify-start items-center">
              <div className="flex-row flex">
                <Checkbox
                  checked={se2019Higher === 'YES'}
                  color={'primary'}
                  onChange={() => setSE2019Higher(se2019Higher === 'YES' ? 'NO' : 'YES')}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, alignItems: 'flex-start', paddingTop: 3 }}
                />
                <p className="text-sm">
                  My income in 2019 were higher than in 2020.
                  <br />
                  <i className="text-xs">
                    (If your 2019 income was higher, you may qualify for a higher refund. If in doubt, include this for
                    the CPA.)
                  </i>
                </p>
              </div>
            </div>
            {se2019Higher === 'YES' ? (
              <FormSelect
                autoCapitalize="none"
                autoComplete=""
                autoSelect={false}
                rules={{ validate: validateIncome }}
                name="netIncome2019Range"
                label=""
                placeholder="Estimated Income"
                options={incomeOptions}
                optionLabels={incomeOptionLabels}
                inputStartAdornmentText="$"
                {...formMethods}
              />
            ) : // <FormTextInput
            //   stripLeadingZeros
            //   rules={{ validate: validateIncome }}
            //   name={'netIncome2019'}
            //   {...formMethods}
            //   type="number"
            //   InputProps={{
            //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
            //   }}
            //   helperText={
            //     !!netIncome2019 && netIncome2019 <= (netIncome2020 ?? 0)
            //       ? 'This income will not be tracked unless it is higher than your entry for 2020.'
            //       : undefined
            //   }
            // />
            null}
            <div className="h-1" />
            <Typography variant="h6" className="mb-2">
              For year 2020
            </Typography>
            {/* <FormTextInput
              stripLeadingZeros
              rules={{ validate: validateIncome }}
              name={'netIncome2020'}
              {...formMethods}
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            /> */}
            <FormSelect
              autoCapitalize="none"
              autoComplete=""
              autoSelect={false}
              rules={{ validate: validateIncome }}
              name="netIncome2020Range"
              label=""
              placeholder="Estimated Income"
              options={incomeOptions}
              optionLabels={incomeOptionLabels}
              inputStartAdornmentText="$"
              {...formMethods}
            />
            <div className="h-1" />
            <Typography variant="h6" className="mb-2">
              For year 2021
            </Typography>
            <FormSelect
              autoCapitalize="none"
              autoComplete=""
              autoSelect={false}
              rules={{
                validate: (val) => {
                  devlog('val', val)
                  return validateIncome(val) ? true : 'At least one taxt year must have income.'
                },
              }}
              name="netIncome2021Range"
              label=""
              placeholder="Estimated Income"
              options={incomeOptions}
              optionLabels={incomeOptionLabels}
              inputStartAdornmentText="$"
              {...formMethods}
            />
            {/* <FormTextInput
              stripLeadingZeros
              rules={{
                validate: (val) => (validateIncome(val) ? true : 'At least one taxt year must have income.'),
              }}
              name={'netIncome2021'}
              {...formMethods}
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            /> */}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className="flex justify-center items-center p-4">
            <Typography variant="body2" className="text-center" style={{ maxWidth: 720, fontStyle: 'italic' }}>
              Your CPA will use your actual net earnings to calculate your actual refund once we receive your tax
              transcripts.
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <div className="h-4" />
      <CreditSummaryFooter onBack={onBack} onNext={handleSubmit(onSubmit)} isPosting={isPosting} />
    </div>
  )
}
