import { covidDates2020Atom, covidDates2021Atom, dateStepAtom } from '@/app/atoms'
import { trackUniqueEvent } from '@/app/hooks/useGTagOnRender'
import useQueryUser, { QueryUserResponse } from '@/app/hooks/useQueryUser'
import useUserInfo from '@/app/hooks/useUserInfo'
import { devlog } from '@/app/utils'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { DateSteps, DatesPageProps, Steps, UserFormProps } from '../FormTypes'
import DateCaringForChild from './DateCaringForChild'
import DateCaringForOther from './DateCaringForOther'
import DateCaringForSelf from './DateCaringForSelf'
import DateCreditSummary from './DateCreditSummary'
import DateIntro from './DateIntro'



export const EnterDates: React.FC<UserFormProps> = ({ onBack, onContinue }) => {
  const [dateStep, setDateStep] = useAtom(dateStepAtom)
  const [covidDates2020, setCovidDates2020] = useAtom(covidDates2020Atom)
  const [covidDates2021, setCovidDates2021] = useAtom(covidDates2021Atom)
  const [userInfo, setUserInfo] = useUserInfo()
  const { postUser } = useQueryUser()
  const [isPosting, setIsPosting] = useState(false)

  const updateDates: DatesPageProps['updateDates'] = ({ dates2020 = [], dates2021 = [], reason }) => {

    devlog('updateDates', dates2020, dates2021, reason );
    let _covidDates2020: CovidDateRange[] = dates2020
    let _covidDates2021: CovidDateRange[] = dates2021
    covidDates2020.forEach((date) => {
      if (date.reason !== reason && !_covidDates2020.some((_date) => date.start === _date.start)) {
        _covidDates2020.push(date)
      }
    })
    covidDates2021.forEach((date) => {
      if (date.reason !== reason && !_covidDates2021.some((_date) => date.start === _date.start)) {
        _covidDates2021.push(date)
      }
    })
    setCovidDates2020(_covidDates2020)
    setCovidDates2021(_covidDates2021)
  }

  const [lastUpdate, setLastUpdate] = useState<string>(JSON.stringify({ covidDates2020, covidDates2021 }))

  const userHasDatesOfType = (reason: CovidDateRange['reason']) => {
    if (!userInfo) {
      return false
    }
    return (
      userInfo.covidDates2020?.some?.((range) => range.reason === reason) ||
      userInfo.covidDates2021?.some?.((range) => range.reason === reason)
    )
  }

  // useEffect(() => {
  //   if (!!userInfo.covidDates2020 && userInfo.covidDates2020.length > 0 && covidDates2020.length === 0) {
  //     setCovidDates2020(userInfo.covidDates2020)
  //   }
  //   if (!!userInfo.covidDates2021 && userInfo.covidDates2021.length > 0 && covidDates2021.length === 0) {
  //     setCovidDates2021(userInfo.covidDates2021)
  //   }
  //   if (userHasDatesOfType('OTHER')) {
  //     devlog('user has other')
  //     setDateStep(DateSteps.creditSummary)
  //   } else if (userHasDatesOfType('PERSONAL')) {
  //     devlog('user has personal')
  //     setDateStep(DateSteps.datesForAnother)
  //   } else if (userHasDatesOfType('CHILD')) {
  //     devlog('user has child')
  //     setDateStep(DateSteps.datesForYourself)
  //   }
  // }, [userInfo.id])

  const postUpdate = async (shouldContinue?: boolean, shouldUpdateEstimate?: boolean) => {
    const update = { covidDates2020, covidDates2021 }
    if (JSON.stringify(update) !== lastUpdate || shouldContinue) {
      setIsPosting(true)
      postUser?.({ ...update, step: shouldContinue ? Steps.TaxInfo : Steps.EnterDates }).then(
        (response: QueryUserResponse) => {
          if (response.success) {
            setLastUpdate(JSON.stringify(update))
            devlog('postUpdate >> setting user info w/Response', shouldContinue)
            setUserInfo(response.userInfo)
            if (shouldContinue && userInfo.id) {
              onContinue?.()
              trackUniqueEvent('dates_completed', 1, userInfo.step ?? 1, userInfo.id)
            } else {
              setDateStep(dateStep + 1)
            }
          } else {
            console.error('EnterDates >> postUser >> Unable to update dates successfully', response)
          }
        }
      )
      setIsPosting(false)
    } else {
      setDateStep(dateStep + 1)
    }
  }

  if (dateStep === DateSteps.dateIntro) {
    return <DateIntro onContinue={() => setDateStep(dateStep + 1)} onBack={() => onBack?.()} />
  } else if (dateStep === DateSteps.datesForChild) {
    return (
      <DateCaringForChild
        isPosting={isPosting}
        covidDates2020={covidDates2020}
        covidDates2021={covidDates2021}
        updateDates={updateDates}
        onContinue={() => postUpdate()}
        onBack={() => setDateStep(dateStep - 1)}
      />
    )
  } else if (dateStep === DateSteps.datesForYourself) {
    return (
      <DateCaringForSelf
        isPosting={isPosting}
        covidDates2020={covidDates2020}
        covidDates2021={covidDates2021}
        updateDates={updateDates}
        onContinue={() => postUpdate()}
        onBack={() => setDateStep(dateStep - 1)}
      />
    )
  } else if (dateStep === DateSteps.datesForAnother) {
    return (
      <DateCaringForOther
        isPosting={isPosting}
        covidDates2020={covidDates2020}
        covidDates2021={covidDates2021}
        updateDates={updateDates}
        onContinue={() => postUpdate(false, true)}
        onBack={() => setDateStep(dateStep - 1)}
      />
    )
  } else if (dateStep === DateSteps.creditSummary) {
    return <DateCreditSummary onContinue={() => postUpdate(true)} onBack={() => setDateStep(dateStep - 1)} />
  }

  return <DateIntro onContinue={() => setDateStep(dateStep + 1)} />
}
