import { Autocomplete, InputAdornment, InputProps, TextField, UseAutocompleteProps } from '@mui/material'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { FormInputProps } from '../FormTypes'
import { devlog } from '@/app/utils'

export const FormSelect: React.FC<
  Omit<
    UseAutocompleteProps<string | number, boolean | undefined, boolean | undefined, boolean | undefined>,
    'autoComplete'
  > &
    FormInputProps & {
      options: Array<string | number>
      optionLabels?: Array<string>
      autoCapitalize: InputProps['autoCapitalize']
      autoComplete: InputProps['autoComplete']
      placeholder?: string
      inputStartAdornmentText?: string
    }
> = ({
  name,
  control,
  label,
  rules,
  options,
  optionLabels,
  helpText,
  autoCapitalize,
  autoComplete,
  placeholder,
  autoSelect = true,
  ...fieldProps
}) => {
  const [open, setOpen] = useState(false)
  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
        return (
          <Autocomplete
            size="small"
            options={options ?? []}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={value}
            onChange={(e, value) => {
              devlog('FS::onChange -> value', value)
              onChange(value)
            }}
            autoSelect={autoSelect}
            getOptionLabel={(option) => {
              // devlog('getOptionLabel', option)
              if (!optionLabels?.length) {
                // devlog('GOL >> A')
                return option.toString()
              }
              if (option === undefined) {
                // devlog('GOL >> B')
                return 'Choose'
              }
              const idx = options.findIndex((opt) => opt === option)
              if (idx === -1) {
                // devlog('GOL >> C');
                return option.toString()
              }
              // devlog('GOL >> D');
              return optionLabels[idx]
            }}
            autoHighlight
            blurOnSelect
            autoComplete
            renderInput={(params) => (
              <TextField
                name={name}
                {...params}
                onChange={(e) => {
                  let val = e.target.value
                  if(!val) {
                    onChange(null)
                    setOpen(false)
                    return;
                  }
                  let valNum: number | null = null
                  try {
                    valNum = parseInt(val)
                  } catch (err) {
                    valNum = null
                  }

                  let index: number | null = null
                  devlog('FS::valNum', valNum)

                  if (valNum && !isNaN(valNum)) {
                    const matches = options.filter((option) => option.toString().startsWith(val))
                    devlog('FS::🚀  matches:', matches)
                    if (matches.length === 1) {
                      index = options.findIndex((option) => option === matches[0])
                      devlog('FS::🚀  match:', index, matches[0])
                    } else {
                      index = null
                    }
                  } else {
                    index = options.findIndex((option) => val && (option.toString()).toLowerCase() === val.toLowerCase())
                  }
                  devlog('FS::val::index', index)
                  if (index && index > -1) {
                    onChange(options[index])
                    setOpen(false)
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: fieldProps.inputStartAdornmentText ? (
                    <InputAdornment position="start">{fieldProps.inputStartAdornmentText}</InputAdornment>
                  ) : null,
                }}
                placeholder={placeholder}
                autoComplete={autoComplete}
                autoCapitalize={autoCapitalize}
                helperText={error?.message ?? helpText}
                error={!!error}
                label={`${label}${rules?.required ? '*' : ''}`}
              />
            )}
          />
        )
      }}
    />
  )
}
