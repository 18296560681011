import CreditSummaryFooter from '@/app/components/CreditSummaryFooter'
import useGTagOnRender from '@/app/hooks/useGTagOnRender'
import useScrollToTopOnSecondRender from '@/app/hooks/useScrollToTopOnSecondRender'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Card, Grid, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import pluralize from 'pluralize'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DatesData, DatesPageProps, UserFormProps } from '../FormTypes'
import { FormDatePickerRangeList } from '../formComponents/FormDatePicker'

const DateCaringForChild: React.FC<UserFormProps & DatesPageProps> = ({
  onContinue,
  onBack,
  updateDates,
  covidDates2020,
  covidDates2021,
  isPosting,
}) => {
  const {
    handleSubmit,
    reset: _reset,
    formState: { isValid, errors, isDirty },
    ...formMethods
  } = useForm<DatesData>({
    defaultValues: {
      covidDates2020: covidDates2020 ?? [],
      covidDates2021: covidDates2021 ?? [],
    },
    mode: 'onChange',
  })
  useScrollToTopOnSecondRender()
  useGTagOnRender('date-for-child')

  const daysAvailable = useMemo(() => {
    let days = { '2020': 10, '2021': 10 }
    covidDates2020?.forEach((range) => (range.reason !== 'CHILD' ? (days[2020] -= range.days) : null))
    covidDates2021?.forEach((range) => (range.reason !== 'CHILD' ? (days[2021] -= range.days) : null))
    return days
  }, [covidDates2020.length, covidDates2021.length])

  const { covidDates2020: childDates2020, covidDates2021: childDates2021 } = formMethods.watch()

  const [sumDays, setSumDays] = useState<number>()
  useEffect(() => {
    let sum = 0
    childDates2020?.map((range) => (range.reason === 'CHILD' ? (sum += parseInt(`${range.days}`)) : null))
    childDates2021?.map((range) => (range.reason === 'CHILD' ? (sum += parseInt(`${range.days}`)) : null))
    let remoteSum = 0
    covidDates2020?.map((range) => (range.reason === 'CHILD' ? (remoteSum += parseInt(`${range.days}`)) : null))
    covidDates2021?.map((range) => (range.reason === 'CHILD' ? (remoteSum += parseInt(`${range.days}`)) : null))

    if (remoteSum !== sum) {
      updateDates({ dates2020: childDates2020, dates2021: childDates2021, reason: 'CHILD' })
    }
    setSumDays(sum)
  }, [childDates2020?.length, childDates2021?.length])

  return (
    <div
      className="grid gap-3 justify-stretch items-start grid-rows-5 direction-col flex-1 pt-8"
      style={{
        gridTemplateRows: 'auto auto 1fr auto auto',
        width: '100%',
      }}>
      <Card className="py-4 px-2">
        <Typography
          className="text-gradient text-center "
          variant="h6"
          sx={{
            fontSize: '1.4rem !important',
            mt: 0,
            mb: 4,
            lineHeight: 1,
            fontWeight: '700',
          }}>
          Schools / Childcare Days
        </Typography>
        <div>
          <div className="flex flex-1 flex-col items-center">
            <div style={{ maxWidth: 720 }}>
              <div className="text-base text-center">
                During 2020 or 2021, if you had a child enrolled in{' '}
                <strong>school or childcare that closed or quarantined</strong>, you can claim dates you were unable to
                work:
              </div>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  See Qualifying Reasons
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="grid grid-cols-1 flex-1 gap-2 items-stretch justify-items-stretch"
                    style={{ gridTemplateColumns: '1fr' }}>
                    <div style={{}}>
                      <ul>
                        <li className="text-sm text-black mb-2">
                          Your child&apos;s <span className="font-bold">school closed</span> or switched to online
                          learning due to COVID
                        </li>
                        <li className="text-sm text-black mb-2">
                          Your child&apos;s regular <span className="font-bold">care provider</span> (friend, family
                          member, etc.) was <span className="font-bold">unavailable</span> for reasons related to COVID
                        </li>
                        <li className="text-sm text-black mb-2">
                          Your child&apos;s <span className="font-bold">daycare</span> or other childcare was{' '}
                          <span className="font-bold">closed</span> for reasons related to COVID
                        </li>
                        <li className="text-sm text-black mb-2">
                          Any similar situation where you could not work due to your child&apos;s{' '}
                          <span className="font-bold">place of care</span> or{' '}
                          <span className="font-bold">care provider</span> being{' '}
                          <span className="font-bold">unavailable</span> for COVID related reasons
                        </li>
                      </ul>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          {/*<hr style={{ color: 'lightgray' }} />*/}
        </div>
      </Card>
      <Grid container gridTemplateRows={'auto auto auto 1fr'} display={'grid'} gap={3}>
        <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center p-4 gap-3">
            <h5 className="text-start font-normal" style={{ maxWidth: 720, margin: 0 }}>
              Helpful Reminders
            </h5>
            <ul>
              <li className="text-sm text-text-body" style={{ maxWidth: 720, lineHeight: 1.45 }}>
                <span>
                  The FFCRA offers self-employed individuals financial relief for up to{' '}
                  <span className="font-bold">130</span> days of missed work due to the reasons listed above.
                </span>
                <ul>
                  <li>
                    <span className="font-bold">20</span> days to care for others
                  </li>
                  <li>
                    <span className="font-bold">110</span> days of family leave
                  </li>
                </ul>
              </li>
              <li className="text-sm text-text-body" style={{ maxWidth: 720, lineHeight: 1.45 }}>
                We will automatically apply these dates in the category to get you your best credit.
              </li>
              <li className="text-sm text-text-body" style={{ maxWidth: 720, lineHeight: 1.45 }}>
                Please note that the same date can only be used once per category.
              </li>
              <li className="text-sm text-text-body" style={{ maxWidth: 720, lineHeight: 1.45 }}>
                <span className="font-bold">Schools were closed</span> because of the pandemic from March 2020 for
                several months. The exact dates varied depending on the school district. If parents or caregivers were
                impacted by COVID-19, school breaks and holidays could be used as family care days.
              </li>
            </ul>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center p-4 gap-3">
            <Box
              sx={{
                gap: 2,
                display: 'flex',
                flexDirection: 'column',
                // flexWrap: 'wrap',

                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.4rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 500,
                }}>
                Select up to 50 days
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontSize: '.8rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 400,
                }}>
                (between 4/1/2020 and 3/31/2021)
              </Typography>
            </Box>

            <div className="flex flex-row justify-center items-center">
              <FormDatePickerRangeList
                name="covidDates2020"
                label=""
                helpText="Please track those days:"
                minDate="2020-04-01"
                maxDate="2021-03-31"
                shouldReset={false}
                maxDays={50}
                reason="CHILD"
                pickedRanges={covidDates2020}
                {...formMethods}
              />
            </div>
            {daysAvailable[2020] > 0 ? (
              <span className={'text-xs text-green-800 font-medium -mt-2'}>
                {`(+${daysAvailable[2020]} carryover ${pluralize('day', daysAvailable[2020])} from "care for others")`}
              </span>
            ) : null}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center p-4 gap-3">
            <Box
              sx={{
                gap: 2,
                display: 'flex',
                flexDirection: 'column',
                // flexWrap: 'wrap',

                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.4rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 500,
                }}>
                Select up to 60 days
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontSize: '.8rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 400,
                }}>
                (between 4/1/2021 and 9/30/2021)
              </Typography>
            </Box>

            <div className="flex flex-row justify-center items-center">
              <FormDatePickerRangeList
                name="covidDates2021"
                label=""
                helpText="Please track those days:"
                minDate="2021-04-01"
                maxDate="2021-09-30"
                shouldReset={false}
                maxDays={60}
                reason="CHILD"
                pickedRanges={covidDates2021}
                {...formMethods}
              />
            </div>
            {daysAvailable[2021] > 0 ? (
              <span className={'text-xs text-green-800 font-medium -mt-2'}>
                {`(+${daysAvailable[2021]} carryover ${pluralize('day', daysAvailable[2021])} from "care for others")`}
              </span>
            ) : null}
          </Card>
        </Grid>
      </Grid>

      <CreditSummaryFooter onBack={onBack} onNext={onContinue} isPosting={isPosting} />
    </div>
  )
}

export default DateCaringForChild
