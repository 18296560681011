import { Box, Button, Link, Paper, Typography } from '@mui/material'
import { useAtomValue } from 'jotai'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { applicantInfoAtom } from '../atoms'
import ResumeButton from '../components/ResumeButton'
import useGTagOnRender, { trackUniqueEvent } from '../hooks/useGTagOnRender'
import useQueryUser from '../hooks/useQueryUser'
import useScrollToTopOnSecondRender from '../hooks/useScrollToTopOnSecondRender'
import useUserInfo from '../hooks/useUserInfo'
import { devlog } from '../utils'
import { RegisterData, Steps, UserFormProps } from './FormTypes'
import { FormInputCheckbox } from './formComponents/FormCheckbox'
import { FormTextInput } from './formComponents/FormTextInput'
import { FormPhoneInput } from './formComponents/FormPhoneInput'
import { CheckCircleOutline } from '@mui/icons-material'

const AccountInfo: React.FC<UserFormProps & { referralCode?: string }> = ({ onContinue, referralCode }) => {
  const [userInfo, , loaded] = useUserInfo()
  const [registered, setRegistered] = useState(!!userInfo?.id)

  if (userInfo?.id) {
    devlog('Account info opened, user already registered.', userInfo)
  }

  useEffect(() => {
    if (!!userInfo.id) {
      setRegistered(true)
    }
  }, [userInfo.id])

  if (!loaded) {
    return null
  }

  return (
    <>
      {!registered ? (
        <RegistrationForm onContinue={() => setRegistered(true)} referralCode={referralCode} />
      ) : !userInfo.phone ? (
        <PhoneComponent />
      ) : (
        <WelcomeComponent onContinue={onContinue} />
      )}
    </>
  )
}

const RegistrationForm: React.FC<UserFormProps & { referralCode?: string }> = ({ onContinue, referralCode }) => {
  useScrollToTopOnSecondRender()
  useGTagOnRender('register')
  const { postUser } = useQueryUser()
  const [, setUserInfo] = useUserInfo()
  const [foundEmail, setFoundEmail] = useState<string>()
  const applicantInfo = useAtomValue(applicantInfoAtom)

  const [isPosting, setIsPosting] = useState(false)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    ...formMethods
  } = useForm<RegisterData>({
    defaultValues: {
      firstName: applicantInfo.first_name ?? '',
      lastName: applicantInfo.last_name ?? '',
      email: applicantInfo.email ?? '',
      termsAndConditions: false,
    },
    mode: 'onChange',
  })

  useEffect(() => {
    devlog('applicant info from url loaded', applicantInfo)
    if (applicantInfo.first_name && !formMethods.getValues('firstName')) {
      formMethods.setValue('firstName', applicantInfo.first_name ?? '')
      formMethods.setValue('lastName', applicantInfo.last_name ?? '')
      formMethods.setValue('email', applicantInfo.email ?? '')
    }
  }, [applicantInfo.first_name])

  const onSubmit: SubmitHandler<RegisterData> = async (data) => {
    setIsPosting(true)
    let referral_code: string | undefined = localStorage.getItem('referral_code') ?? referralCode
    //referrerCode will get turned into a referrer id in the backend and attached to the table
    // const updated = await updateData?.(dataForDB)
    const updated = await postUser({ ...data, referrerCode: referral_code })
    setIsPosting(false)
    devlog('AccountInfo >> Register submitted', updated)
    if (updated?.success && updated.userInfo.id) {
      setUserInfo(updated.userInfo)
      trackUniqueEvent('sign_up', -1, -1, updated.userInfo.id)
      onContinue()
    } else if (updated?.error === 'Email already exists') {
      formMethods.setError('email', { type: 'manual', message: 'Email already exists' })
      setFoundEmail(data.email)
    } else {
      console.error('Unhandled registration error', updated)
    }
  }

  return (
    <div className="flex flex-1 justify-center items-center flex-col">
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 600,
          flexShrink: 1,
        }}>
        <Box padding={4} className="flex flex-1 flex-col gap-4 pt-8 px-8 items-stretch prequal-survey-step">
          <Typography variant="h5" className="text-gradient-light text-center">
            Create your account
          </Typography>
          <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
            Applications are processed in the order they are received. Save your work and resume at any time.
          </Typography>
          <div className="border-gray-200 border-solid my-4" style={{ borderWidth: 1 }}></div>
          <FormTextInput
            rules={{ required: true, minLength: { value: 2, message: 'Please enter your first name' } }}
            name="firstName"
            label="First Name"
            autoComplete="given-name"
            disabled={isPosting}
            {...formMethods}
          />
          <FormTextInput
            rules={{ required: true, minLength: { value: 2, message: 'Please enter your last name' } }}
            name="lastName"
            label="Last Name"
            autoComplete="family-name"
            disabled={isPosting}
            {...formMethods}
          />
          <FormTextInput
            name="email"
            type="email"
            autoComplete="email"
            rules={{
              required: true,
              pattern: { value: /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/, message: 'Please enter a valid email' },
            }}
            label="Email"
            disabled={isPosting}
            {...formMethods}
          />
          <FormInputCheckbox
            rules={{ required: 'You must accept the Terms & Conditions to continue' }}
            name="termsAndConditions"
            label="Accept Terms & Conditions"
            {...formMethods}
          />
          <Link paddingLeft={4} target="_blank" href="https://claimmy.credit/terms">
            <Typography className={'text-button'} variant={'body2'}>
              View Terms & Conditions Here
            </Typography>
          </Link>

          <div className="italic text-sm">
            Create an account and get your CPA certified estimate with no obligation. Your information will not be
            shared or sold.
          </div>
          <div className="h-8" />
          <Box className="flex flex-row justify-end">
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isPosting}
              className="w-48">
              Next
            </Button>
          </Box>
        </Box>
      </Paper>
      <div className="flex flex-row justify-end items-end absolute top-0 right-2  pt-2">
        <ResumeButton buttonText="Login" />
      </div>
    </div>
  )
}

const PhoneComponent: React.FC<{}> = ({}) => {
  useScrollToTopOnSecondRender()
  useGTagOnRender('register_phone')
  const { postUser } = useQueryUser()
  const [userInfo, setUserInfo] = useUserInfo()
  const applicantInfo = useAtomValue(applicantInfoAtom)

  const [isPosting, setIsPosting] = useState(false)
  const {
    handleSubmit,
    reset,
    formState: { errors },
    ...formMethods
  } = useForm<{ phone: string }>({
    defaultValues: {
      phone: applicantInfo.phone ? `+1${applicantInfo.phone}` : '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    if (applicantInfo.phone && !formMethods.getValues('phone')) {
      devlog('applicant phone from url loaded', applicantInfo)
      formMethods.setValue('phone', applicantInfo.phone ? `+1${applicantInfo.phone}` : '')
    }
  }, [applicantInfo.phone])

  const onSubmit: SubmitHandler<{ phone: string }> = async (data) => {
    setIsPosting(true)
    //referrerCode will get turned into a referrer id in the backend and attached to the table
    // const updated = await updateData?.(dataForDB)
    const updated = await postUser({ ...data, step: Steps.FinancialInfo })
    setIsPosting(false)
    devlog('AccountInfo >> Register submitted', updated)
    if (updated?.success && updated.userInfo.id) {
      trackUniqueEvent('sign_up_phone', -1, -1, updated.userInfo.id)
      setUserInfo(updated.userInfo)
    } else {
      console.error('Unhandled registration error', updated)
    }
  }

  return (
    <div className="flex flex-1 justify-center items-center flex-col">
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 600,
          flexShrink: 1,
        }}>
        <Box padding={4} className="flex flex-1 flex-col pt-8 px-8 items-stretch prequal-survey-step">
          <h4 className="text-center text-slate-800 mt-4 mb-6 leading-10">
            <Typography variant="h3" className="text-gradient-light text-center">
              Hi {userInfo.firstName}!
            </Typography>
            What&apos;s your phone number?
          </h4>
          <div className="flex flex-col justify-start items-start self-center gap-2">
            <div className="flex flex-row justify-center items-center">
              <CheckCircleOutline className='text-green-700' />
              <div className='ml-4'>Required by CPA</div>
            </div>
            <div className="flex flex-row justify-center items-center">
              <CheckCircleOutline className='text-green-700' />
              <div className='ml-4'>No spam, just help</div>
            </div>
            <div className="flex flex-row justify-center items-center">
              <CheckCircleOutline className='text-green-700' />
              <div className='ml-4'>We won&apos;t sell your number</div>
            </div>
            <div className="flex flex-row justify-center items-center">
              <CheckCircleOutline className='text-green-700' />
              <div className='ml-4'>Your data is protected</div>
            </div>
          </div>

          <div className='h-12' />
          <div className="flex flex-col items-stretch">
            <FormPhoneInput
              autoComplete={'tel'}
              name="phone"
              label="Phone Number"
              disabled={isPosting}
              {...formMethods}
            />
          </div>
          <div className="h-12" />
          <div className='text-sm' ><span className='font-bold'>By pressing next you opt-in</span> to receive text messages from us for the express purpose of assisting you in filing your application, via status updates, reminder texts, conversing with a representative, and other messages to assist in servicing your application and helping you claim your SETC tax credit. You may opt-out or cancel your application at any time. Learn more on our <a href="https://claimmy.credit/terms">Terms of Service and Privacy Policy</a></div>
          <div className="h-12" />
          <Box className="flex flex-row justify-center">
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isPosting}
              className="w-48">
              Next
            </Button>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}

const WelcomeComponent: React.FC<{ onContinue: () => void }> = ({ onContinue }) => {
  useScrollToTopOnSecondRender()
  useGTagOnRender('post-register-welcome')

  return (
    <div className="flex flex-1 flex-col items-center px-4 ">
      <div className="flex flex-1 flex-col items-center w-full md:w-4/5">
        <Typography variant="h5" className=" text-gradient text-center p-5">
          Welcome to <br /> Claim My Credit
        </Typography>
        <div className="grid gap-4 items-center justify-center ">
          <div style={{ height: 2 }} className="primary-button w-3/4 md:w-3/4 mx-auto" />
          <Typography variant="h6" className="text-center py-0 my-0">
            What to expect
          </Typography>
          <ul className="list-disc mt-0">
            <li className="list-decimal mb-2">
              We&apos;ll gather some financial information for the relevant tax years.
            </li>
            <li className="list-decimal mb-2">
              We&apos;ll gather some important dates from you and calculate your estimated refund amount.
            </li>
            <li className="list-decimal mb-2">Upload you original tax returns using our secure document portal.</li>
            <li className="list-decimal mb-2">Upload a picture of your drivers license.</li>
            <li className="list-decimal mb-2">
              Your dedicated CPA will review your application and finalize your refund amount.
            </li>
            <li className="list-decimal mb-2">After review your CPA will create and file for your refund.</li>
          </ul>
          <div style={{ height: 2 }} className="primary-button w-3/4 md:w-3/4 mx-auto mb-10" />
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '200px' }}>
          <Button className="w-full" type="submit" variant="contained" onClick={onContinue}>
            Next
          </Button>
        </Box>
      </div>
    </div>
  )
}

export default AccountInfo
