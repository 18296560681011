import React, { useState, useEffect } from 'react'
import { boxTokenAtom, boxUserFolderIdAtom, userIdAtom, userTokenAtom } from '../atoms'
import { useAtom, useAtomValue } from 'jotai'
import { devlog } from '../utils'

const test_user_id = process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_DEFAULTID : ''
const parseUrl = process.env.NEXT_PUBLIC_PARSE_URL

export default function useGetBoxToken() {
  const [boxToken, setBoxToken] = useAtom(boxTokenAtom)
  const [boxUserFolderId, setBoxUserFolderId] = useAtom(boxUserFolderIdAtom)
  const _userId = useAtomValue(userIdAtom)
  const userId = _userId || test_user_id
  const [fetching, setFetching] = useState(false)
  const userToken = useAtomValue(userTokenAtom)

  useEffect(() => {
    async function fetchBoxToken() {
      setFetching(true)
      const response = await fetch(`${parseUrl}/api/box/user/${userId}/folder`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      const data = await response.json()
      devlog('🚀  fetchBoxToken >> data:', data)
      setBoxToken(data.accessToken)
      setBoxUserFolderId(data.folderId)
      setFetching(false)
    }
    if (!boxToken && !fetching && userId) {
      fetchBoxToken()
    }
  }, [userId])

  return boxToken
}
