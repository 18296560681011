import { devlog } from '@/app/utils'
import { useWindowSize } from '@/app/utils/useWindowSize'
import AddIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import pluralize from 'pluralize'
import React, { useEffect, useMemo, useState } from 'react'
import { DateRange, Range, RangeFocus } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Controller } from 'react-hook-form'
import { FormInputProps } from '../FormTypes'

const dbFormat = 'YYYY-MM-DD'

export const FormDatePicker: React.FC<FormInputProps & Partial<DatePickerProps<any>>> = ({
  name,
  control,
  label,
  rules,
  autoComplete,
  ...fieldProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: props, fieldState: { error } }) => (
        <Box width={'100%'} gridRow={'auto'}>
          <DesktopDatePicker
            sx={{ width: '100%' }}
            {...props}
            value={props?.value ? dayjs(props?.value) : null}
            {...fieldProps}
            onChange={(val) => props.onChange(val?.isValid() ? getDateString(val) : undefined)}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
                fullWidth: true,
                size: 'small',
                autoComplete,
                label,
              },
            }}
          />
        </Box>
      )}
    />
  )
}

type Reason = 'PERSONAL' | 'CHILD' | 'OTHER' | ''

interface DateRangeInnerProps {
  name?: string
  minDate?: string
  maxDate?: string
  startPicker?: DatePickerProps<any> & { label?: string }
  endPicker?: DatePickerProps<any> & { label?: string }
  label?: string
  onChange?: (range: { start: string; end: string; days: number }, reset: () => void) => void
  requireAccept?: boolean
  value?: { start: string; end: string }
  defaultValue?: { start: string; end: string }
  showDays?: boolean
  pickedRanges?: Array<CovidDateRange>
  isAdding?: boolean
  setIsAdding?: (adding: boolean) => void
  reason?: Reason
  onCancel?: () => void
}
const DateRangeInner: React.FC<DateRangeInnerProps> = ({
  name,
  minDate: _minDate,
  maxDate: _maxDate,
  startPicker,
  endPicker,
  label,
  onChange,
  value,
  defaultValue,
  showDays = true,
  requireAccept,
  pickedRanges,
  isAdding,
  setIsAdding,
  reason,
  onCancel,
}) => {
  const [shouldReset, setShouldReset] = useState(false)
  const [endDate, setEndDate] = useState(
    defaultValue?.start ?? value?.start ? dayjs(defaultValue?.start ?? value?.start) : null
  )
  const [startDate, setStartDate] = useState(
    defaultValue?.end ?? value?.end ? dayjs(defaultValue?.end ?? value?.end) : null
  )
  const dayCount = useMemo(() => {
    if (endDate && startDate) {
      const count = endDate.diff(startDate, 'days') + 1
      return count
    }
    return undefined
  }, [endDate, startDate])

  useEffect(() => {
    // devlog('CHECK REPORT useEffect', !!startDate, !!endDate, dayCount )
    if (endDate && startDate && dayCount && !requireAccept && !shouldReset) {
      reportChange()
    }
  }, [endDate, startDate, dayCount])

  const reportChange = () => {
    if (startDate && endDate && dayCount) {
      onChange?.(
        {
          start: startDate.format(dbFormat),
          end: endDate.format(dbFormat),
          days: dayCount,
        },
        () => {
          // setStartDate(dayjs(defaultValue?.start ?? value?.start))
          // setEndDate(dayjs(defaultValue?.start ?? value?.start))
          setShouldReset(true)
        }
      )
    }
  }

  const { minDate, maxDate, startEnd, endStart } = useMemo(() => {
    const newMinDate = _minDate ?? startPicker?.minDate ? dayjs(_minDate ?? startPicker?.minDate) : undefined
    const newMaxDate = _maxDate ?? endPicker?.maxDate ? dayjs(_maxDate ?? endPicker?.maxDate) : undefined
    const newStartEnd = !endDate ? newMaxDate?.subtract(1, 'day') : dayjs(endDate).subtract(1, 'day')
    const newEndStart = !startDate ? newMinDate?.add(1, 'day') : dayjs(startDate).add(1, 'day')
    return {
      minDate: newMinDate,
      maxDate: newMaxDate,
      startEnd: newStartEnd,
      endStart: newEndStart,
    }
  }, [_minDate, startPicker?.minDate, _maxDate, endPicker?.maxDate, endDate, startDate])

  useEffect(() => {
    // devlog('range value change', { value, endDate, startDate })
    if (value?.start !== startDate?.format(dbFormat)) {
      setStartDate(value?.start ? dayjs(value.start) : null)
    }
    if (value?.end !== endDate?.format(dbFormat)) {
      setEndDate(value?.end ? dayjs(value.end) : null)
    }
  }, [value?.start, value?.end])

  useEffect(() => {
    if (shouldReset) {
      setStartDate(null)
      setEndDate(null)
      setShouldReset(false)
      setIsAdding?.(false)
    }
  }, [shouldReset])

  if (shouldReset) {
    return null
  }

  return (
    <Box width={'100%'} gridRow={'auto'}>
      {label ? <Typography variant={'body1'}>{label}</Typography> : null}
      <Grid container id="form-date-picker-row-container">
        <Grid
          container
          item
          xs={12}
          spacing={0}
          direction={'column'}
          justifyContent={'space-between'}
          alignItems={'center'}
          wrap="nowrap"
          gap={2}
          padding={0}>
          {isAdding && (
            <RangePicker
              minDate={minDate?.toDate()}
              maxDate={maxDate?.toDate()}
              disabledDates={pickedRanges}
              onSelect={(range) => {
                setStartDate(dayjs(range.startDate))
                setEndDate(dayjs(range.endDate))
              }}
            />
          )}
          {!isAdding && (
            <Button
              type="submit"
              variant="contained"
              name={`${name}_add`}
              onClick={() => setIsAdding?.(true)}
              className="w-48">
              + Add Dates
            </Button>
          )}
        </Grid>
        <Grid container item xs={12} justifyContent={'center'} alignItems={'center'}>
          {showDays ? (
            <Typography variant={'body1'}>
              {`${dayCount ?? 0 + pluralize(' day', dayCount ?? 0)} currently selected`}
            </Typography>
          ) : null}
          {requireAccept ? (
            <Button disabled={!dayCount} onClick={reportChange} variant={'text'}>
              <AddIcon />
              <Typography variant={'body2'}>Accept Dates</Typography>
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}

type FormDatePickerRangeProps = DateRangeInnerProps & FormInputProps

// export const FormDatePickerRange: React.FC<FormDatePickerRangeProps> = ({
//   minDate,
//   maxDate,
//   startPicker,
//   endPicker,
//   name,
//   control,
//   label,
// }) => {
//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field: props }) => {
//         return (
//           <DateRangeInner
//             {...{
//               minDate,
//               maxDate,
//               startPicker,
//               endPicker,
//               label,
//               onChange: props.onChange,
//               defaultValue: props.value,
//               value: props.value,
//             }}
//           />
//         )
//       }}
//     />
//   )
// }

const getDateString = (date: string | Date) => dayjs(date).format(dbFormat)
const getDisplayDate = (date: string | Date) => dayjs(date).format('MM/DD/YYYY')

type FormDatePickerRangeListProps = FormDatePickerRangeProps & {
  showDayCount?: boolean
  shouldReset?: boolean
  maxDays?: number
  reason: Reason
}

// const SelectInputOverride = styled(InputBase)(({ theme }) => ({
//   'label + &': {
//     marginTop: theme.spacing(3),
//   },
//   '& .MuiInputBase-input': {
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: theme.palette.background.paper,
//     border: 0,
//     fontSize: 16,
//     padding: '10px 26px 10px 12px',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     // Use the system font instead of the default Roboto font.
//     display: 'flex',
//     justifyContent: 'center',
//     fontFamily: [
//       'inter',
//       '-apple-system',
//       'BlinkMacSystemFont',
//       '"Segoe UI"',
//       'Roboto',
//       '"Helvetica Neue"',
//       'Arial',
//       'sans-serif',
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(','),
//     '&:focus': {
//       borderRadius: 4,
//       borderColor: '#80bdff',
//       boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
//     },
//   },
// }))

export const FormDatePickerRangeList: React.FC<FormDatePickerRangeListProps> = ({
  minDate,
  maxDate,
  startPicker,
  endPicker,
  name,
  control,
  label,
  helpText,
  showDayCount = true,
  shouldReset,
  maxDays,
  pickedRanges,
  reason: _reason,
  ...fieldProps
}) => {
  const [reason, setReason] = useState<Reason>(_reason)
  const [isAdding, setIsAdding] = useState(false)
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: props }) => {
        const [dateRanges, setDateRanges] = useState<Array<CovidDateRange>>(props.value ?? [])
        const pickedSums = useMemo(() => {
          let ranges = pickedRanges ?? dateRanges
          let sums = {
            CHILD: 0,
            SHIFTED: 0,
            PERSONAL: 0,
            OTHER: 0,
            TOTAL: 0,
          }
          let errorLogged = false
          for (const range of ranges) {
            if (isNaN(range.days) && !errorLogged) {
              console.error('Ranges include invalid days count', ranges)
              errorLogged = true
            } else {
              sums[range.reason as keyof typeof sums] += parseInt(`${range.days}`)
            }
          }
          if (reason === 'CHILD') {
            const availableOther = Math.max(10 - sums.PERSONAL - sums.OTHER, 0)
            sums.SHIFTED = availableOther > 0 ? Math.min(sums.CHILD, availableOther) : 0
            sums.TOTAL = sums.CHILD + sums.SHIFTED
          } else {
            sums.TOTAL = sums.PERSONAL + sums.OTHER
          }
          return sums
        }, [(pickedRanges ?? dateRanges)?.map((range) => range.days)])
        const { width = 1000 } = useWindowSize()
        useEffect(() => {
          if (shouldReset) {
            setDateRanges([])
          }
        }, [shouldReset])
        useEffect(() => {
          props.onChange(dateRanges)
        }, [dateRanges.map((range) => range.start + range.end).join()])

        return (
          <Box width={'100%'} id={`${name}-container`} flexDirection={'column'}>
            <Grid container gap={2} py={2} width={'100%'} direction={'column'}>
              <ul style={width < 750 ? { padding: 0 } : undefined}>
                {dateRanges.map(({ start, end, days, reason: _reason }, i) =>
                  _reason !== reason ? null : (
                    <li key={`${name}-value-${i}`}>
                      <Grid
                        container
                        width={'100%'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        mb={2}
                        borderBottom={1}
                        borderColor={'InactiveBorder'}>
                        <div>
                          <Typography variant="subtitle2">{`${getDisplayDate(start)} - ${getDisplayDate(
                            end
                          )}`}</Typography>
                        </div>
                        <div className={width > 750 ? 'w-12' : 'w-6'} />
                        <Typography variant="body1">{`${
                          showDayCount ? ` ${days} ${pluralize('day', days)}` : ''
                        }`}</Typography>
                        <div className={width > 750 ? 'w-8' : 'w-2'} />
                        <IconButton
                          onClick={() => {
                            setIsAdding(false)
                            dateRanges.splice(i, 1)
                            props.onChange(dateRanges)
                            // setDateRanges(dateRanges)
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </li>
                  )
                )}
              </ul>
            </Grid>

            <DateRangeInner
              {...{
                name,
                pickedRanges: [...dateRanges, ...(pickedRanges ?? [])],
                reason,
                minDate,
                maxDate,
                startPicker,
                endPicker,
                requireAccept: false,
                showDays: false,
                isAdding,
                setIsAdding,
                onChange: (val, reset) => {
                  setDateRanges([...dateRanges, { ...val, reason }])
                  reset()
                },
              }}
            />
            <div className="text-center subtitle2 text-text-body mt-4" style={{ maxWidth: 720, lineHeight: 1.45 }}>
              <span>
                You have selected{' '}
                <span className="font-bold text-black">{pickedSums[reason as keyof typeof pickedSums]}</span> days out
                of{' '}
                <span className="font-bold text-black">
                  {(maxDays ?? 0) + (reason === 'CHILD' ? Math.max(10 - pickedSums.PERSONAL - pickedSums.OTHER, 0) : 0)}
                </span>{' '}
                available
              </span>
            </div>
          </Box>
        )
      }}
    />
  )
}

// const checkDateOverlap = ({
//   date,
//   start,
//   end,
//   otherEnd,
// }: {
//   date: dayjs.Dayjs
//   start: dayjs.Dayjs
//   end: dayjs.Dayjs
//   otherEnd: dayjs.Dayjs | null
// }) => {
//   return (
//     (date.isBefore(start) && (!otherEnd || otherEnd.isBefore(start))) ||
//     (date.isAfter(end) && (!otherEnd || otherEnd.isAfter(end)))
//   )
//   // start and end can share days
//   // const dateIsBeforeStart = isStart ? date.isBefore(start) : date.isBefore(start) || date.isSame(start)
//   // const dateIsAfterEnd = isStart ? date.isAfter(end) || date.isSame(end) : date.isAfter(end)

//   // const otherEndIsBeforeStart =
//   //   !otherEnd || (!isStart ? otherEnd.isBefore(start) : otherEnd.isBefore(start) || otherEnd.isSame(start))
//   // const otherEndIsAfterEnd =
//   //   !otherEnd || (!isStart ? otherEnd.isAfter(end) || otherEnd.isSame(end) : otherEnd.isAfter(end))

//   // return !((dateIsBeforeStart && otherEndIsBeforeStart) || (dateIsAfterEnd && otherEndIsAfterEnd))
// }

// const checkDateForDisable = ({
//   date,
//   reason,
//   pickedRanges,
//   otherEnd,
// }: {
//   date: dayjs.Dayjs
//   reason: CovidDateRange['reason']
//   pickedRanges: Array<CovidDateRange>
//   otherEnd: dayjs.Dayjs | null
// }) => {
//   for (let range of pickedRanges) {
//     if (getReasonMatch(reason, range.reason)) {
//       let start = dayjs(range.start)
//       let end = dayjs(range.end)
//       if (checkDateOverlap({ date, start, end, otherEnd })) {
//         return true
//       }
//     }
//   }

//   return false
// }
const getValidStart = (disabledDates?: Array<CovidDateRange>, minDate?: Date, maxDate?: Date) => {
  const min = minDate ? dayjs(minDate) : null
  const max = maxDate ? dayjs(maxDate) : dayjs()
  let day = min ?? dayjs()
  do {
    if (!shouldDisableDate(day.toDate(), disabledDates, minDate, maxDate)) {
      return day.toDate()
    }
    day = day.add(1, 'day')
  } while (day.isBefore(max))
  return undefined
}

const rangeIsValid = (date: Range, disabledDates?: Array<CovidDateRange>, minDate?: Date, maxDate?: Date) => {
  if (date.startDate && date.endDate) {
    return !shouldDisableDate(date.startDate, disabledDates, minDate, maxDate, date.endDate)
  }
  return false
}

const shouldDisableDate = (
  date: Date,
  disabledDates?: Array<CovidDateRange>,
  minDate?: Date,
  maxDate?: Date,
  otherEnd?: Date
) => {
  const min = minDate ? dayjs(minDate) : null
  const max = maxDate ? dayjs(maxDate) : null
  let blockReason = null
  if (!!disabledDates) {
    for (let range of disabledDates) {
      const day = dayjs(date)
      const start = dayjs(range.start)
      const end = dayjs(range.end)
      if (!(day.isBefore(start) || day.isAfter(end))) {
        blockReason = 'in a range'
        break
      }
      if (min && day.isBefore(min)) {
        blockReason = 'before min'
        break
      }
      if (max && day.isAfter(max)) {
        blockReason = 'after max'
        break
      }
      if (otherEnd) {
        const _otherEnd = dayjs(otherEnd)
        if ((day.isBefore(start) && _otherEnd.isAfter(end)) || (day.isAfter(end) && _otherEnd.isBefore(start))) {
          blockReason = 'spans range'
          break
        }
      }
    }
  }
  return !!blockReason
}
interface RangePickerProps {
  disabledDates?: Array<CovidDateRange>
  minDate?: Date
  maxDate?: Date
  onSelect: (range: { startDate: string; endDate: string }) => void
}

const RangePicker: React.FC<RangePickerProps> = ({ minDate, maxDate, onSelect, disabledDates }) => {
  const [selectedRange, setSelectedRange] = useState<Range>({
    startDate: getValidStart(disabledDates, minDate, maxDate),
    endDate: getValidStart(disabledDates, minDate, maxDate),
    key: 'selection',
  })
  const [rangeFocus, setRangeFocus] = useState<RangeFocus>([0, 0])
  const isPickingEnd = rangeFocus[0] === 0 && rangeFocus[1] === 1
  const { width = 1080 } = useWindowSize()
  const selectDate = (date: Range) => {
    if (isPickingEnd) {
      if (rangeIsValid(date, disabledDates, minDate, maxDate)) {
        onSelect({
          startDate: getDateString(date?.startDate ?? ''),
          endDate: getDateString(date?.endDate ?? ''),
        })
      }
    }
    setSelectedRange(date)
  }

  const disabledDatesExpanded = useMemo(() => {
    const _disabledDates: Date[] = []
    let dateCursor
    disabledDates?.map((range) => {
      dateCursor = dayjs(range.start)
      while (dateCursor.isBefore(range.end) || dateCursor.isSame(range.end)) {
        _disabledDates.push(dateCursor.toDate())
        dateCursor = dateCursor.add(1, 'day')
      }
    })
    return _disabledDates
  }, [disabledDates])

  return (
    <div style={{}}>
      <DateRange
        editableDateInputs={true}
        minDate={minDate}
        maxDate={maxDate}
        dragSelectionEnabled={false}
        onChange={(item) => {
          selectDate(item.selection)
        }}
        onRangeFocusChange={(item) => {
          devlog('onRangeFocusChange > item', item)
          setRangeFocus(item)
        }}
        disabledDates={disabledDatesExpanded}
        preventSnapRefocus={true}
        moveRangeOnFirstSelection={false}
        ranges={[selectedRange]}
        months={width >= 750 ? 2 : 1}
        direction="horizontal"
      />
    </div>
  )
}
