import CreditSummaryFooter from '@/app/components/CreditSummaryFooter'
import useGTagOnRender from '@/app/hooks/useGTagOnRender'
import useScrollToTopOnSecondRender from '@/app/hooks/useScrollToTopOnSecondRender'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Card, Grid, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DatesData, DatesPageProps, UserFormProps } from '../FormTypes'
import { FormDatePickerRangeList } from '../formComponents/FormDatePicker'
const DateCaringForSelf: React.FC<UserFormProps & DatesPageProps> = ({
  onContinue,
  onBack,
  updateDates,
  covidDates2020,
  covidDates2021,
  isPosting,
}) => {
  const {
    handleSubmit,
    reset: _reset,
    formState: { isValid, errors, isDirty },
    ...formMethods
  } = useForm<DatesData>({
    defaultValues: {
      covidDates2020: covidDates2020.filter((range) => range.reason === 'PERSONAL') ?? [],
      covidDates2021: covidDates2021.filter((range) => range.reason === 'PERSONAL') ?? [],
    },
    mode: 'onChange',
  })
  useScrollToTopOnSecondRender()
  useGTagOnRender('date-for-self')

  const { covidDates2020: selfDates2020, covidDates2021: selfDates2021 } = formMethods.watch()

  const [sumDays, setSumDays] = useState<number>()
  useEffect(() => {
    let sum = 0
    selfDates2020?.map((range) => (range.reason === 'PERSONAL' ? (sum += parseInt(`${range.days}`)) : null))
    selfDates2021?.map((range) => (range.reason === 'PERSONAL' ? (sum += parseInt(`${range.days}`)) : null))
    let remoteSum = 0
    covidDates2020?.map((range) => (range.reason === 'PERSONAL' ? (remoteSum += parseInt(`${range.days}`)) : null))
    covidDates2021?.map((range) => (range.reason === 'PERSONAL' ? (remoteSum += parseInt(`${range.days}`)) : null))
    if (remoteSum !== sum) {
      updateDates({ dates2020: selfDates2020, dates2021: selfDates2021, reason: 'PERSONAL' })
    }
    setSumDays(sum)
  }, [selfDates2020?.length, selfDates2021?.length])

  return (
    <div
      className="grid gap-3 justify-stretch items-start grid-rows-5 direction-col flex-1 pt-8"
      style={{
        gridTemplateRows: 'auto auto 1fr auto auto',
        width: '100%',
      }}>
      <Card className="py-4 px-2">
        <Typography
          className="text-gradient text-center "
          variant="h6"
          sx={{
            fontSize: '1.4rem !important',
            mt: 0,
            mb: 4,
            lineHeight: 1,
            fontWeight: '700',
          }}>
          Caring For Yourself
        </Typography>
        <div className="flex flex-1 flex-col items-center">
          <div style={{ maxWidth: 720 }}>
            <div className="subtitle1 text-center">
              Please choose the days you couldn&apos;t work as a self-employed person due to any of the following
              reasons:
            </div>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                See Valid Reasons
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className="grid grid-cols-1 flex-1 gap-2 items-stretch justify-items-stretch"
                  style={{ gridTemplateColumns: '1fr' }}>
                  <div style={{}}>
                    <ul>
                      <li className="text-sm text-black mb-2">
                        You were experiencing symptoms of COVID-19 and seeking a medical diagnosis
                      </li>
                      <li className="text-sm text-black mb-2">
                        You were seeking or awaiting the results of a diagnostic test for, or a medical diagnosis of
                        COVID-19 example
                      </li>
                      <li className="text-sm text-black mb-2">
                        You were exposed to COVID-19 or were unable to work pending the results of a test or diagnosis
                        example
                      </li>
                      <li className="text-sm text-black mb-2">
                        You were obtaining immunization related to COVID-19 example
                      </li>
                      <li className="text-sm text-black mb-2">
                        You were recovering from any injury, disability, illness, or condition related to such
                        immunization example
                      </li>
                    </ul>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </Card>

      <Grid container gridTemplateRows={'auto auto auto 1fr'} gap={3}>
        <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center py-4 pr-2">
            <h5 className="text-start font-normal" style={{ maxWidth: 720, margin: 0 }}>
              Remember
            </h5>
            <ul>
              <li className="text-sm text-text-body" style={{ maxWidth: 720, lineHeight: 1.45 }}>
                The FFCRA offers self-employed individuals financial relief for up to{' '}
                <span className="font-bold">20</span> days of missed work due to COVID-related issues for personal care
                and caring for others.
              </li>
              <li className="text-sm text-text-body" style={{ maxWidth: 720, lineHeight: 1.45 }}>
                Please note that you cannot use the same date twice across any of these categories.
              </li>
            </ul>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center px-4 py-6 gap-1">
            <Box
              sx={{
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.4rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 500,
                }}>
                Select up to
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.4rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: '700',
                }}>
                10 days
              </Typography>
            </Box>

            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '.8rem !important',
                mt: 1,
                lineHeight: 1,
              }}>
              (between 4/1/2020 and 3/31/2021)
            </Typography>
            <div className="flex flex-row justify-center items-center">
              <FormDatePickerRangeList
                name="covidDates2020"
                label=""
                helpText="Please track those days:"
                minDate="2020-04-01"
                maxDate="2021-03-31"
                shouldReset={false}
                maxDays={10}
                reason="PERSONAL"
                pickedRanges={covidDates2020}
                {...formMethods}
              />
            </div>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center px-4 py-6 gap-1">
            <Box
              sx={{
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.4rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: 500,
                }}>
                Select up to
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.4rem !important',
                  mt: 0,
                  lineHeight: 1,
                  fontWeight: '700',
                }}>
                10 days
              </Typography>
            </Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '.8rem !important',
                mt: 1,
                lineHeight: 1,
                // fontWeight: 500,
              }}>
              ( between 4/1/2021 and 9/30/2021)
            </Typography>
            <div className="flex flex-row justify-center items-center">
              <FormDatePickerRangeList
                name="covidDates2021"
                label=""
                helpText="Please track those days:"
                minDate="2021-04-01"
                maxDate="2021-09-30"
                shouldReset={false}
                maxDays={10}
                reason="PERSONAL"
                pickedRanges={covidDates2021}
                {...formMethods}
              />
            </div>
          </Card>
        </Grid>
      </Grid>

      <CreditSummaryFooter onBack={onBack} onNext={onContinue} isPosting={isPosting} />
    </div>
  )
}

export default DateCaringForSelf
