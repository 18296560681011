import CreditSummaryFooter from '@/app/components/CreditSummaryFooter'
import useGTagOnRender from '@/app/hooks/useGTagOnRender'
import useScrollToTopOnSecondRender from '@/app/hooks/useScrollToTopOnSecondRender'
import { ArrowBack } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useAtomValue } from 'jotai'
import React from 'react'
import { summaryCreditAtom } from '../../atoms'
import { UserFormProps } from '../FormTypes'

const DateCreditSummary: React.FC<UserFormProps & {}> = ({ onContinue, onBack }) => {
  const { unusedDays, total, totalUpper } = useAtomValue(summaryCreditAtom)
  useScrollToTopOnSecondRender()
  useGTagOnRender('date-credit-summary')

  const unusedPersonalDays = Math.max(unusedDays[2020].PERSONAL, 0) + Math.max(unusedDays[2021].PERSONAL, 0)
  const unusedChildDays = Math.max(unusedDays[2020].CHILD, 0) + Math.max(unusedDays[2021].CHILD, 0)
  const unusedSum = unusedPersonalDays + unusedChildDays

  return (
    <div
      className="grid gap-3 justify-stretch items-start grid-rows-5 direction-col flex-1 pt-2 px-2"
      style={{
        gridTemplateRows: 'auto auto 1fr auto auto',
        width: '100%',
      }}>
      <Grid container gridTemplateRows={'auto auto auto 1fr'} display={'grid'} gap={1}>
        <Grid item xs={12}>
          <h5 className="text-gradient text-center">Great News!</h5>
        </Grid>
        <Grid item xs={12}>
          <div className="flex justify-center items-center">
            <div className="body1 max-w-lg text-center font-semibold">
              Using the data you provided we have calculated your FFCRA credits. It looks like you qualify for up to:
            </div>
          </div>
          <div className="h-4" />
          <h3 className="m-0 text-center text-gradient-light">
            {total.toString().asCurrencyShort()}
            {totalUpper ? ' - ' + totalUpper.toString().asCurrencyShortCeil() : ''}
          </h3>
        </Grid>
        {total <= 500 ? (
          <>
            <Grid item xs={12}>
              <div className="body1 text-center font-semibold">{`A CPA review might find more in available credits.`}</div>
            </Grid>
          </>
        ) : null}
        {unusedSum > 0 ? (
          <div className="bg-white rounded-lg mt-4 mb-8">
            <div className="h-2" />
            <Grid item xs={12}>
              <div className="text-center text-lg font-semibold text-gray-800">{`You may qualify for more:`}</div>
              <div className="text-sm italic text-center text-gray-500">
                ({unusedPersonalDays > 0 ? unusedPersonalDays + ' Personal days' : ''}
                {unusedChildDays > 0 && unusedPersonalDays > 0 ? ', ' : ''}
                {unusedChildDays > 0 ? unusedChildDays + ' Child days' : ''}) ={' '}
                <span className="font-bold text-gray-600">up to {(32220 - total).toString().asCurrency()}</span>
              </div>
              <div className="body1 text-center mb-2">{` available to claim.`}</div>
              <div
                className="p-2 py-4 flex justify-center items-center gap-2 text-purple-700 underline text-center"
                onClick={() => onBack?.()}>
                <ArrowBack className="" />
                <div>Go back and claim more days</div>
              </div>
            </Grid>
          </div>
        ) : null}
        <Grid item xs={12}>
          <h6 className="text-center font-bold" style={{ margin: 0 }}>
            Here&apos;s what comes next:
          </h6>
        </Grid>
        <Grid item xs={12}>
          <div className="body1 text-center">1. Complete your application</div>
        </Grid>
        <Grid item xs={12}>
          <div className="body1 text-center">
            2. Our trusted CPA partners will review your application and amend your returns
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="body1 text-center">3. Sign your completed documents</div>
        </Grid>
        <Grid item xs={12}>
          <div className="body1 text-center">4. Relax and receive your refunds</div>
        </Grid>
      </Grid>
      <div className="h-2" />
      <CreditSummaryFooter onBack={onBack} onNext={onContinue} isPosting={false} />

      <div className="flex justify-center items-center">
        <div className="body2 text-center text-orange-500 max-w-xl">
          *If you do not have outstanding tax liabilities, you will receive funds directly. If you have outstanding tax
          liabilities, these tax credits will be used to offset them. The credit provided above has fees included.
        </div>
      </div>
      <div className="h-4" />
    </div>
  )
}

export default DateCreditSummary
