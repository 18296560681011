import CreditSummaryFooter from '@/app/components/CreditSummaryFooter'
import useGTagOnRender from '@/app/hooks/useGTagOnRender'
import useScrollToTopOnSecondRender from '@/app/hooks/useScrollToTopOnSecondRender'
import { Card, Grid } from '@mui/material'
import React from 'react'
import { UserFormProps } from '../FormTypes'

const DateIntro: React.FC<UserFormProps & {}> = ({ onContinue, onBack }) => {
  useScrollToTopOnSecondRender()
  useGTagOnRender('date-intro')
  // const [hasChildren, setHasChildren] = useState<'YES' | 'NO'>()

  return (
    <div
      className="grid gap-3 justify-stretch items-start grid-rows-5 direction-col flex-1 pt-6"
      style={{
        gridTemplateRows: 'auto auto 1fr auto auto',
      }}>
      <h5 className="text-gradient text-center">It&apos;s all about the dates</h5>
      <div>
        <hr style={{ color: 'black' }} />
      </div>
      <Grid container gridTemplateRows={'auto auto auto 1fr'} display={'grid'} gap={3}>
        <Grid item xs={12}>
          <Card className="flex flex-row justify-start items-center p-2 pr-0 gap-3">
            <Grid container flex={1}>
              <Grid item xs={12} md={6} xl={8} className="flex flex-col justify-start items-start p-4">
                <Grid container flex={1}>
                  <Grid item xs={12} xl={6} className="flex flex-col justify-start items-start p-2">
                    <h5 className="text-start font-normal" style={{ maxWidth: 720, margin: 0 }}>
                      Financial relief for up to <span className="font-bold">130</span> days of missed work 🎉
                    </h5>
                  </Grid>
                  <Grid item xs={12} xl={6} className="flex flex-col justify-start items-start p-0">
                    <ul className="pl-4 md:pl-8">
                      <li className="body2 text-black mb-4">
                        The FFCRA provides financial help to self-employed people when they can&apos;t work because of
                        COVID-related problems. It covers both <span className="font-bold">personal</span> and{' '}
                        <span className="font-bold">family</span> care needs.
                      </li>
                      <li className="body2 text-black mb-4">
                        You can get this help for up to <span className="font-bold">20</span> days for personal care, or
                        care for someone else.
                      </li>
                      <li className="body2 text-black mb-4">
                        Another <span className="font-bold">110</span> days of family leave help are available for
                        school and child care closures and quarantines.
                      </li>

                      <li className="body2 text-black mb-4">
                        The amount of money you receive as a tax credit depends on the number of days you pick.
                      </li>

                      <li className="body2 text-black mb-2">
                        For any credit, you can&apos;t choose the same dates more than once 😎
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* <Grid item xs={12}>
          <Card className="flex flex-col justify-start items-center p-6 gap-3">
            <ul className="pl-4 md:pl-6">
              <li className="body2 text-black mb-4">
                <span className="font-bold">110</span> days of family leave help are available for school and child care
                closures and quarantines.
              </li>
            </ul>
            <div className="text-black underline">Do you have children?</div>
            <div className="flex flex-row gap-4 flex-1 justify-start items-center">
              <div className="flex-row flex">
                <Checkbox
                  checked={hasChildren === 'YES'}
                  color={'primary'}
                  onChange={() => setHasChildren('YES')}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, alignItems: 'flex-start', paddingTop: 3 }}
                />
                <p className="text-sm">Yes</p>
              </div>
              <div className="flex-row flex">
                <Checkbox
                  checked={hasChildren === 'NO'}
                  color={'primary'}
                  onChange={() => setHasChildren('NO')}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, alignItems: 'flex-start', paddingTop: 3 }}
                />
                <p className="text-sm">No</p>
              </div>
            </div>
          </Card>
        </Grid> */}
      </Grid>
      <CreditSummaryFooter onBack={onBack} onNext={onContinue} isPosting={false} />
    </div>
  )
}

export default DateIntro
