import { FormControl, TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { FormInputProps } from '../FormTypes'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'

export const FormPhoneInput: React.FC<FormInputProps & TextFieldProps & { stripLeadingZeros?: boolean }> = ({
  name,
  control,
  label,
  type,
  helperText,
  InputProps,
  rules,
  stripLeadingZeros = true,
  autoComplete,
  ...fieldProps
}) => {
  return (
    <Controller
      rules={{ ...rules, validate: matchIsValidTel }}
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <FormControl error={!!error}>
          <MuiTelInput
            label={label}
            InputLabelProps={{ style: { backgroundColor: 'white' } }}
            autoComplete={autoComplete}
            defaultCountry="US"
            onlyCountries={['US']}
            disableDropdown
            size="small"
            helperText={!!error ? 'Please enter a valid telephone number' : helperText}
            error={!!error}
            value={value}
            onChange={(val, info) => {
              if (val === '' || !val.startsWith('+1')) {
                onChange('+1' + val.slice(1))
                return
              }
              onChange(info.numberValue)
            }}
            onBlur={onBlur}
          />
        </FormControl>
      )}
    />
  )
}
