import React, { useState, useEffect } from 'react'

export default function useScrollToTopOnSecondRender() {
  const [firstRender, setFirstRender] = useState(false)

  useEffect(() => {
    setFirstRender(true)
  }, [])

  useEffect(() => {
    if (firstRender) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setFirstRender(false)
    }
  }, [firstRender])
}
