'use client'
import { ArrowBack } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { dateStepAtom, summaryCreditAtom, userInfoReducerAtom } from '../atoms'
import { DateSteps, Steps } from '../steps/FormTypes'

export default function CreditSummaryFooter({
  onBack,
  onNext,
  isPosting,
  nextText,
  hideNext,
}: {
  onBack?: () => void
  onNext?: () => Promise<void> | void
  isPosting?: boolean
  nextText?: string
  hideNext?: boolean
}) {
  const summaryCredit = useAtomValue(summaryCreditAtom)
  const dateStep = useAtomValue(dateStepAtom)
  const userInfo = useAtomValue(userInfoReducerAtom)

  const upperTotal = useMemo(() => {
    if (!userInfo.covidDates2020?.length && !userInfo.covidDates2021?.length) {
      return summaryCredit.totalUpper?.toString()
    }

    if ((userInfo.step ?? 0) < Steps.TaxInfo) {
      const hasPersonal =
        userInfo.covidDates2020?.some((date) => date.reason === 'PERSONAL') ||
        userInfo.covidDates2021?.some((date) => date.reason === 'PERSONAL')
      const hasOther =
        userInfo.covidDates2020?.some((date) => date.reason === 'OTHER') ||
        userInfo.covidDates2021?.some((date) => date.reason === 'OTHER')
      const hasChild =
        userInfo.covidDates2020?.some((date) => date.reason === 'CHILD') ||
        userInfo.covidDates2021?.some((date) => date.reason === 'CHILD')

      const shouldUseActualPersonal = hasPersonal || dateStep > DateSteps.datesForYourself
      const actualPersonalEstimate =
        (summaryCredit.breakdownUpper?.totalPersonal2020 ?? 0) + (summaryCredit.breakdownUpper?.totalPersonal2021 ?? 0)
      const guestimatePersonal =
        (summaryCredit.guesstimate?.breakdownUpper?.totalPersonal2020 ?? 0) +
        (summaryCredit.guesstimate?.breakdownUpper?.totalPersonal2021 ?? 0)
      const shouldUseActualOther = hasOther || dateStep > DateSteps.datesForAnother
      const actualOtherEstimate =
        (summaryCredit.breakdownUpper?.totalOther2020 ?? 0) + (summaryCredit.breakdownUpper?.totalOther2021 ?? 0)
      const guestimateOther =
        (summaryCredit.guesstimate?.breakdownUpper?.totalOther2020 ?? 0) +
        (summaryCredit.guesstimate?.breakdownUpper?.totalOther2021 ?? 0)
      const shouldUseActualChild = hasChild || dateStep > DateSteps.datesForChild
      const actualChildEstimate =
        (summaryCredit.breakdownUpper?.totalChild2020 ?? 0) + (summaryCredit.breakdownUpper?.totalChild2021 ?? 0)
      const guestimateChild =
        (summaryCredit.guesstimate?.breakdownUpper?.totalChild2020 ?? 0) +
        (summaryCredit.guesstimate?.breakdownUpper?.totalChild2021 ?? 0)

      const upperEstimateParts = [
        shouldUseActualPersonal ? actualPersonalEstimate : guestimatePersonal,
        shouldUseActualOther ? actualOtherEstimate : guestimateOther,
        shouldUseActualChild ? actualChildEstimate : guestimateChild,
      ]
      return upperEstimateParts.reduce((acc, val) => acc + val, 0)?.toString()
    }
    return summaryCredit.totalUpper?.toString()
  }, [userInfo.step, dateStep, summaryCredit.total])

  const totalDaysUsed = useMemo(() => {
    return (
      (userInfo.covidDates2020 ?? []).reduce((acc, val) => acc + val.days, 0) +
      (userInfo.covidDates2021 ?? []).reduce((acc, val) => acc + val.days, 0)
    )
  }, [userInfo.covidDates2020?.length, userInfo.covidDates2021?.length])

  return (
    <div className="flex flex-col justify-between fixed bottom-0 left-0 right-0 bg-white z-40 drop-shadow-lg shadow-lg shadow-gray-900">
      {totalDaysUsed && userInfo.step === Steps.EnterDates ? (
        <div className="bg-purple-700 text-white py-1">
          <div className="text-xs text-center font-semibold">{totalDaysUsed} days claimed of 130</div>
        </div>
      ) : null}
      <div className="flex flex-row justify-between">
        <div className="p-2 py-4 flex justify-start items-center w-24 " onClick={() => !isPosting && onBack?.()}>
          <ArrowBack className="text-gray-600" />
        </div>

        <div className="flex  w-auto justify-center flex-col items-center flex-[4]">
          <div className="text-sm text-black font-medium text-right">Est Refund</div>

          <div className="text-gradient font-bold text-1xl ">
            {summaryCredit.total.toString().asCurrencyShort()}
            {upperTotal ? ' - ' + upperTotal.asCurrencyShortCeil() : ''}
          </div>
        </div>
        {hideNext ? (
          <div className="w-24 flex-1" />
        ) : (
          <div className="flex flex-row gap-2 py-4 w-24 pr-2 justify-end">
            <Button type="submit" variant="contained" disabled={isPosting} onClick={onNext}>
              {nextText ?? 'Next'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
